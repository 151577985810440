import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../state/store";
import { useParams } from "react-router-dom";
import ProductCard from "./productCard";
import { useEffect } from "react";
import { pagination } from "../state/productsSlice";

export default function FilteredProducts() {
  const dispatch = useDispatch<AppDispatch>();
  const products = useSelector(
    (state: RootState) => state.productsReducer.filteredProducts
  );
  const pageNumebr = useSelector(
    (state: RootState) => state.productsReducer.pageNumber
  );
  const endingNumber = pageNumebr * 12;
  const startinNumber = endingNumber - 12;

  const params = useParams() as {
    category: "allproducts" | "bodyParts" | "accessories" | "lights";
  };
  useEffect(() => {}, [params]);
  useEffect(() => {
    sessionStorage.setItem("pageNumber", JSON.stringify(pageNumebr));
  }, [pageNumebr]);

  useEffect(() => {
    if (sessionStorage?.getItem("pageNumber")) {
      const currPage = parseInt(
        sessionStorage.getItem("pageNumber") || "1",
        10
      );
      dispatch(
        pagination({
          pageNumber: currPage,
        })
      );
    }
  }, []);

  const previewedProducts = products?.slice(startinNumber, endingNumber);

  return (
    <>
      {previewedProducts?.length ? (
        <>
          {previewedProducts?.map((product) => {
            return (
              <ProductCard
                imageSource={product.coverPhoto.url}
                product={product}
                key={product._id}
              />
            );
          })}
        </>
      ) : (
        <>
          <div className="flex items-center justify-center flex-col gap-2 border py-5 rounded-2xl border-dashed">
            <p className="text-2xl">No Products Found</p>
            <p className="text-2xl">Try to change filters</p>
          </div>
        </>
      )}
    </>
  );
}
