import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../hooks/useAppDispatchSelector";
import {
  addToCart,
  getCartProductsThunk,
  minusFromCart,
} from "../state/cartSlice";
import {
  CheckOutlined,
  PlusOutlined,
  MinusOutlined,
  ArrowRightOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { formatMakeAndModel, formatYearString } from "../../util/util";
import { CartItem } from "../../util/types";
import { Button } from "antd";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { setopenLoginPopup } from "../state/userAuthSlice";
const CartCard = ({ cartItem }: { cartItem: CartItem }) => {
  const dispatch = useAppDispatch();
  return (
    <div className="border rounded-lg p-5 border-[#eee] h-max">
      <div className="flex gap-5 items-center">
        <div className="w-24 h-24">
          <img
            className="h-full w-full rounded-lg"
            src={cartItem.product.coverPhoto.url}
            alt="product"
          />
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-2xl text-black font-semibold">
            {cartItem.product.name}{" "}
            {formatMakeAndModel({
              make: cartItem.product.make,
              model: cartItem.product.model,
            })}{" "}
            /{" "}
            {formatYearString({
              yearFrom: cartItem.product.yearFrom,
              yearTo: cartItem.product.yearTo,
            })}
          </p>
          <p className="text-xl text-black">
            {cartItem.product.hasDiscount
              ? cartItem.product.discountPrice
              : cartItem.product.price}{" "}
            EGP
          </p>
          <p className="text-lg font-semibold text-blue_primary">
            Total:{" "}
            {cartItem.product.hasDiscount
              ? (cartItem.product.discountPrice || 0) * cartItem.count
              : cartItem.product.price * cartItem.count}{" "}
            EGP
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between mt-16 ">
        <p className="text-2xl font-semibold">Quantity</p>
        <div className="w-[35%] h-14 p-4 flex items-center justify-between border rounded-lg">
          <div
            className="border-r pr-5 cursor-pointer"
            onClick={() => {
              dispatch(
                minusFromCart({
                  _id: cartItem.product._id,
                })
              );
            }}
          >
            <MinusOutlined className="text-2xl font-bold" />
          </div>
          <p className="text-2xl font-semibold flex-1 text-center">
            {cartItem.count}
          </p>
          <div
            className="border-l pl-5 cursor-pointer"
            onClick={() => {
              dispatch(
                addToCart({
                  product: {
                    product: cartItem.product,
                    count: 1,
                  },
                })
              );
            }}
          >
            <PlusOutlined className="text-2xl font-bold" />
          </div>
        </div>
      </div>
    </div>
  );
};
const Cart = () => {
  const { userAuthenticated } = useAppSelector(
    (state) => state.userAuthenticationReducer
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const cartItems = useAppSelector((state) => state.CartReducer.cartItems);
  const loadingCartProducts = useAppSelector(
    (state) => state.CartReducer.loadingProducts
  );
  const totalPrice = useAppSelector((state) => state.CartReducer.totalPrice);
  useEffect(() => {
    if (cartItems.length) {
      const productsIds = cartItems.map((product) => product.product._id);
      dispatch(getCartProductsThunk(productsIds));
    }
  }, []);

  return (
    <div>
      {cartItems.length ? (
        <>
          <div className="flex items-center mb-32">
            <div className="w-16 h-16 border-2 border-blue_primary rounded-full flex items-center justify-center text-blue_primary bg-blue_primary relative">
              <CheckOutlined className="text-white text-[15px]" />
              <p className="absolute text-blue_primary -bottom-12 left-0 lg:left-[unset] text-2xl w-max">
                Cart Items
              </p>
            </div>

            <div className="flex-1 h-1 bg-blue_primary" />
            <div className="w-16 h-16 border-2 border-blue_primary rounded-full flex items-center justify-center relative">
              <p className="text-2xl text-blue_primary">2</p>
              <p className="absolute text-blue_primary -bottom-12 right-0 lg:right-[unset] text-2xl w-max">
                Submit Order
              </p>
            </div>
          </div>
          {loadingCartProducts ? (
            <div className="flex items-center justify-center h-[20vh]">
              <LoadingOutlined className="text-7xl" />
            </div>
          ) : (
            <>
              <div className="flex flex-col gap-10">
                {cartItems.map((cartItem) => (
                  <CartCard cartItem={cartItem} key={cartItem.product._id} />
                ))}
              </div>
              <div className="mt-20 border rounded-lg p-5 border-[#eee] h-max flex items-center justify-between">
                <p className="text-2xl">Total Price</p>
                <p className="text-2xl font-semibold">{totalPrice} EGP</p>
              </div>
              <div className="flex justify-end mt-14">
                <Button
                  onClick={() => {
                    // if (!userAuthenticated) {
                    //   dispatch(setopenLoginPopup({ state: true }));
                    //   return;
                    // } else {
                    //   navigate("/checkout");
                    // }
                    navigate("/checkout");
                  }}
                  className="bg-blue_primary text-white flex items-center justify-center lg:justify-between gap-3 mx-auto lg:mx-[unset] lg:w-max w-full py-8 lg:py-[unset]"
                >
                  <p>Complete Order</p>
                  <ArrowRightOutlined />
                </Button>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="flex flex-col items-center justify-center h-[50vh] gap-12 pt-24">
          <FontAwesomeIcon icon={faCartShopping} width={120} size="9x" />
          <p className="text-4xl font-semibold capitalize">
            Your cart is empty
          </p>
          <Button
            className="bg-blue_primary text-white flex items-center justify-between gap-3"
            onClick={() => {
              navigate("/products/allproducts");
            }}
          >
            <p>Back to products</p>
            <ArrowRightOutlined />
          </Button>
        </div>
      )}
    </div>
  );
};
export default Cart;
