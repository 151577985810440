import { LazyLoadImage } from "react-lazy-load-image-component";
import aboutUsImageOne from "./images/about-us.jpeg";
import whyGfast from "./images/why-gfast.jpg";
const AboutUsSection = () => {
  return (
    <div className=" z-20 w-screen bg-white md:py-40 py-20">
      <div className="container mx-auto flex flex-col md:gap-5 gap-24 items-center">
        <div className="flex items-center md:gap-20 gap-10 md:flex-row flex-col text-center md:text-left md:px-0 px-5">
          <div className="flex-1 flex flex-col gap-4 ">
            <p className="text-red_primary font-bold text-5xl mb-5 md:mb-0">
              About Us
            </p>
            <p className="text-gray_primary  md:text-2xl text-xl leading-8 md:leading-[25px]">
              At G-FAST, we are dedicated to revolutionizing the automotive
              repair experience by providing a seamless, user-friendly online
              platform for purchasing high-quality spare parts. Specializing
              initially in MG vehicles from 2018 onwards, our goal is to support
              vehicle owners in restoring their cars to optimal condition
              swiftly and efficiently.
            </p>
          </div>
          <div className=" flex justify-end relative">
            <LazyLoadImage
              className="object-fill h-[300px] rounded-lg shadow-xl"
              src={aboutUsImageOne}
              alt="about us"
            />{" "}
            <div className="absolute top-0 w-full h-full bg-gray_primary opacity-20" />
          </div>
        </div>
        <div className="flex items-center md:gap-20 gap-10 w-full md:flex-row flex-col text-center md:text-left md:px-0 px-5">
          <div className=" flex justify-end relative">
            <LazyLoadImage
              className="object-fill h-[300px] rounded-lg shadow-xl"
              src={whyGfast}
              alt="about us"
            />
          </div>
          <div className="flex-1 flex flex-col gap-4 md:order-1 order-[-1]">
            <p className="text-red_primary font-bold text-5xl mb-5 md:mb-0">
              Why Choose G-FAST?
            </p>
            <p className="text-gray_primary  md:text-2xl text-xl leading-8 md:leading-[25px]">
              We provide a seamless and transparent solution for purchasing
              high-quality spare parts with instant pricing and no hidden costs.
              Offering genuine and cost-effective options, we ensure fast
              24-hour delivery and expert technical support. Our strict quality
              control, warranty, and returns policy guarantee secure purchases.
              Additionally, we offer free accident analysis reports and tailored
              B2B solutions for insurance companies and repair shops,
              streamlining the procurement process.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutUsSection;
