import {
  faTruckFast,
  faStar,
  faHandshakeSimple,
  faCarSide,
} from "@fortawesome/free-solid-svg-icons";
import HomeServiceCard from "./homeServiceCard";

export default function HomeFourthSection() {
  return (
    <div className="relative z-20 flex items-center flex-col bg-white h-max w-full md:pt-40 md:pb-40 pt-20 pb-0 ">
      <div className="relative flex items-center flex-col h-max w-[85vw] sm:w-full">
        <h2 className="capitalize text-center text-4xl sm:text-7xl font-semibold  border-b-2 pb-5 border-b-blue_primary px-5 mb-10 md:mb-20 w-full sm:w-max text-gray_primary">
          What we offer
        </h2>
        <div className="container grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1  justify-items-center mx-auto md:gap-20 px-10">
          <HomeServiceCard
            icon={faTruckFast}
            title="Fast shipping"
            paragraph="24/7 support (by experts)"
          />
          <HomeServiceCard
            icon={faStar}
            title="Our Policy"
            paragraph="We offer hassle-free returns and warranty policies"
          />
          <HomeServiceCard
            icon={faHandshakeSimple}
            title="hassle-free experience"
            paragraph="Enjoy hassle-free experience, from browsing to checkout."
          />
          <HomeServiceCard
            icon={faCarSide}
            title="high quality spare parts"
            paragraph="Competitive pricing without compromising on quality"
          />
        </div>
      </div>
    </div>
  );
}
