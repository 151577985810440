import { useLocation } from "react-router-dom";

const useIsHomepage = (): { isHomepage: boolean } => {
  const location = useLocation();

  return {
    isHomepage:
      location.pathname === "/" ||
      location.pathname === "/about-us" ||
      location.pathname === "/our-service",
  };
};

export default useIsHomepage;
