import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ErrorType, setError } from "./errorSlice";
import {
  CarMake,
  CarMakeModelsResponse,
  CategoriesCount,
  CountCategoriesResponse,
  ProductQualityTypes,
} from "../../util/types";
import { fetchRequest, formatMakeAndModel } from "../../util/util";

type Image = {
  key: string;
  url: string;
};
export type Products = {
  _id: string;
  name: string;
  price: number;
  description: string;
  additionalInfo?: string;
  category: string[];
  colors: string[];
  discountPrice: number | null;
  hasDiscount: boolean;
  descriptionLanguage: "ar" | "en" | null;
  inStock: boolean;
  coverPhoto: Image;
  additionalPhotos?: Image[] | [];
  isShown: boolean;
  make: string;
  model: string;
  productQuality: ProductQualityTypes;
  yearFrom: string;
  yearTo: string;
};

export type FetchResponse = {
  message: string;
  error?: ErrorType;
  products: Products[] | [];
  searchByKeyProducts: Products[] | [];
  productsNumber: number;
  highestPrice: number;
  success: boolean;
  productDetails?: Products;
  randomProducts: Products[];
};

type productsState = {
  loadingProducts: boolean;
  loadingDetailedProduct: boolean;
  loadingMakeModels: boolean;
  loadingCategoriesCount: boolean;
  searchByKeyProducts: Products[] | [];
  originalFilteredProducts: Products[] | [];
  filteredProducts: Products[] | [];
  randomProducts: Products[] | [];
  productsNumber: number;
  pageNumber: number;
  minPriceFilter?: number;
  maxPriceFilter?: number;
  activeFilters: {
    type: "price" | "keyword";
    keyword: string | undefined | null;
  }[];
  productDetails: Products | undefined;
  searchKeyword: string | undefined | null;
  highestPrice: number;
  carMakes: CarMake[];
  categoriesCount: CategoriesCount[];
  allCategoriesCount: number;
};
const initialState: productsState = {
  loadingProducts: true,
  loadingDetailedProduct: true,
  searchByKeyProducts: [],
  filteredProducts: [],
  originalFilteredProducts: [],
  randomProducts: [],
  productsNumber: 0,
  pageNumber: 1,
  maxPriceFilter: undefined,
  minPriceFilter: undefined,
  activeFilters: [],
  productDetails: undefined,
  searchKeyword: undefined,
  loadingMakeModels: true,
  loadingCategoriesCount: true,
  highestPrice: 0,
  allCategoriesCount: 0,
  carMakes: [],
  categoriesCount: [],
};

const products = createSlice({
  name: "products",
  initialState: initialState,
  reducers: {
    sortByPrice(state, action: PayloadAction<string>) {
      if (action.payload === "default") {
        state.filteredProducts = state.originalFilteredProducts;
        return;
      }
      state.filteredProducts = state.filteredProducts.sort((a, b) => {
        let priceA = a.price;
        let priceB = b.price;
        if (a.hasDiscount && a.discountPrice) priceA = a.discountPrice;
        if (b.hasDiscount && b.discountPrice) priceB = b.discountPrice;

        if (action.payload === "priceLow") return +priceA - +priceB;
        if (action.payload === "priceHigh") return +priceB - +priceA;
        else return +a - +b;
      });
    },
    pagination(
      state,
      action: PayloadAction<{ pageNumber?: number; productsNumber?: number }>
    ) {
      if (action.payload.pageNumber) {
        state.pageNumber = action.payload.pageNumber;
      }
      if (action.payload.productsNumber) {
        state.productsNumber = action.payload.productsNumber;
      }
    },
    filterPrice(
      state,
      action: PayloadAction<{
        min: number;
        max: number;
      }>
    ) {
      state.minPriceFilter = action.payload.min;
      state.maxPriceFilter = action.payload.max;

      const existingKeyword = state.activeFilters.find(
        (filter) => filter.type === "price"
      );

      if (existingKeyword) {
        state.activeFilters = state.activeFilters.map((filter) => {
          if (filter.type !== "price") return filter;
          else
            return {
              type: "price",
              keyword: "price",
            };
        });
      } else
        state.activeFilters = [
          ...state.activeFilters,
          { type: "price", keyword: "price" },
        ];
    },
    resetFilterPrice(state) {
      state.minPriceFilter = undefined;
      state.maxPriceFilter = undefined;
      state.activeFilters = state.activeFilters.filter(
        (filter) => filter.keyword !== "price"
      );
    },
    resetProductDetails(state) {
      state.productDetails = undefined;
    },
    setSearchKeyword(state, action: PayloadAction<string | undefined | null>) {
      state.searchKeyword = action.payload;
    },
    filterByKeyword(state) {
      const searchFilter = state?.searchByKeyProducts?.filter((el) => {
        if (state.searchKeyword) {
          return (
            el?.name
              ?.toLowerCase()
              ?.includes(state.searchKeyword.toLowerCase()) ||
            formatMakeAndModel({ make: el.make, model: el.model })
              .toLowerCase()
              .includes(state.searchKeyword.toLowerCase()) ||
            formatMakeAndModel({ make: el.make, model: el.model })
              .replace(" ", "")
              .toLowerCase()
              .includes(state.searchKeyword.toLowerCase())
          );
        } else return el;
      });
      state.filteredProducts = searchFilter;
      if (state.searchKeyword) {
        const existingKeyword = state.activeFilters.find(
          (filter) => filter.type === "keyword"
        );

        if (existingKeyword) {
          state.activeFilters = state.activeFilters.map((filter) => {
            if (filter.type !== "keyword") return filter;
            else
              return {
                type: "keyword",
                keyword: state.searchKeyword,
              };
          });
        } else
          state.activeFilters = [
            ...state.activeFilters,
            { type: "keyword", keyword: state.searchKeyword },
          ];
      } else {
        state.activeFilters = state.activeFilters?.filter(
          (filter) => filter.type !== "keyword"
        );
      }
    },
    clearKeywordFilter(state) {
      state.filteredProducts = state.searchByKeyProducts;
      state.activeFilters = state.activeFilters?.filter(
        (filter) => filter.type !== "keyword"
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProductsThunk.pending, (state) => {
      state.loadingProducts = true;
    });
    builder.addCase(
      getProductsThunk.fulfilled,
      (state, action: PayloadAction<FetchResponse>) => {
        state.loadingProducts = false;
        if (action.payload.error) {
          return;
        }
        state.highestPrice = action.payload.highestPrice;
        state.productsNumber = action.payload.productsNumber;
        state.filteredProducts = action.payload.products;
        state.originalFilteredProducts = action.payload.products;
        state.searchByKeyProducts = action.payload.searchByKeyProducts;
      }
    );
    builder.addCase(getProductsThunk.rejected, (state) => {
      state.loadingProducts = false;
    });
    builder.addCase(getProductDetailsThunk.pending, (state) => {
      state.loadingDetailedProduct = true;
    });
    builder.addCase(
      getProductDetailsThunk.fulfilled,
      (state, action: PayloadAction<FetchResponse>) => {
        state.loadingDetailedProduct = false;
        if (action.payload.error) {
          return;
        }
        state.randomProducts = action.payload.randomProducts;
        state.productDetails = action.payload.productDetails;
      }
    );
    builder.addCase(getProductDetailsThunk.rejected, (state) => {
      state.loadingDetailedProduct = false;
      state.productDetails = undefined;
    });
    builder.addCase(getCarmakesModelsThunk.pending, (state) => {
      state.loadingMakeModels = true;
    });
    builder.addCase(
      getCarmakesModelsThunk.fulfilled,
      (state, action: PayloadAction<CarMakeModelsResponse>) => {
        state.loadingMakeModels = false;
        if (action.payload.error) {
          return;
        }
        state.loadingMakeModels = false;
        state.carMakes = action.payload.data;
      }
    );
    builder.addCase(getCarmakesModelsThunk.rejected, (state) => {
      state.carMakes = [];
      state.loadingMakeModels = false;
    });
    builder.addCase(getCategoriesCountThunk.pending, (state) => {
      state.loadingCategoriesCount = true;
    });

    builder.addCase(
      getCategoriesCountThunk.fulfilled,
      (state, action: PayloadAction<CountCategoriesResponse>) => {
        state.loadingCategoriesCount = false;
        if (action.payload.error) {
          return;
        }
        state.loadingCategoriesCount = false;
        state.categoriesCount = action.payload.categoryCounts;
        state.allCategoriesCount = action.payload.allProducts;
      }
    );
    builder.addCase(getCategoriesCountThunk.rejected, (state) => {
      state.carMakes = [];
      state.loadingCategoriesCount = false;
    });
  },
});

export const getProductsThunk = createAsyncThunk(
  "products/getProducts",
  async ({ category }: { category: string }, { dispatch }) => {
    const searchParams = new URLSearchParams(window.location.search); // Get current URL query params

    const data: FetchResponse = await fetch(
      `${
        process.env.REACT_APP_BACKEND_DOMAIN
      }/products/${category}?${searchParams.toString()}`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: FetchResponse) => {
        if (data.error) {
          throw data;
        }

        return data;
      })
      .catch((err) => {
        dispatch(setError(err));
        return err;
      });
    return data;
  }
);
export const getProductDetailsThunk = createAsyncThunk(
  "products/getProductDetails",
  async (id: string, { dispatch }) => {
    const data: FetchResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/productDetails`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ id }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: FetchResponse) => {
        if (data.error) {
          throw data;
        }

        return data;
      })
      .catch((err) => {
        // if err.code is 401 then dispatch logout function
        return err;
      });
    return data;
  }
);
export const getCarmakesModelsThunk = createAsyncThunk(
  "products/getCarMakesModels",
  async (_, { dispatch }) => {
    const data: CarMakeModelsResponse =
      await fetchRequest<CarMakeModelsResponse>({
        url: `${process.env.REACT_APP_BACKEND_DOMAIN}/make-models`,
        method: "GET",
        dispatch,
      });
    return data;
  }
);
export const getCategoriesCountThunk = createAsyncThunk(
  "products/getCategoriesCount",
  async (_, { dispatch }) => {
    const searchParams = new URLSearchParams(window.location.search);

    const data: CountCategoriesResponse =
      await fetchRequest<CountCategoriesResponse>({
        url: `${
          process.env.REACT_APP_BACKEND_DOMAIN
        }/categoryCount?${searchParams.toString()}`,
        method: "GET",
        dispatch,
      });
    return data;
  }
);
export const {
  sortByPrice,
  pagination,
  resetFilterPrice,
  filterPrice,
  resetProductDetails,
  setSearchKeyword,
  filterByKeyword,
  clearKeywordFilter,
} = products.actions;
export const productsReducer = products.reducer;
