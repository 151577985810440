import { ErrorObject, ErrorType } from "../components/state/errorSlice";
import { Products } from "../components/state/productsSlice";

export enum ProductQualityTypes {
  ORIGINAL = "original",
  HIGH_QUALITY = "high_quality",
}
export type CartItem = {
  product: Products;
  count: number;
};

export type CartSliceType = {
  cartItems: CartItem[];
  totalItemsCount: number;
  totalPrice: number;
  loadingProducts: boolean;
};
export type AddressFormType = {
  city: string;
  area: string;
  phoneNumber: string;
  detailedAddress: string;
};
export type CreateOrderRequest = {
  address: AddressFormType;
  order: { count: number; product: string }[];
};
export type CarMake = {
  make: string;
  logo: string;
  models: string[];
};
export type OrderResponse = {
  message: string;
  error?: ErrorType;
  success: boolean;
  code?: number;
};
export type UserOrderResponse = {
  message: string;
  error?: ErrorType;
  success: boolean;
  code?: number;
  orders: UserOrder[];
};
export type CarMakeModelsResponse = {
  message: string;
  error?: ErrorType;
  success: boolean;
  code?: number;
  data: CarMake[];
};
export type CategoriesCount = { categoryName: string; productCount: number };
export type CountCategoriesResponse = {
  message: string;
  error?: ErrorType;
  success: boolean;
  code?: number;
  categoryCounts: CategoriesCount[];
  allProducts: number;
};
export enum OrderStatusType {
  "INPROGRESS" = "in progress",
  "DELIVERED " = "delivered",
}
export enum PaymentMethodType {
  "CASH" = "cash",
  "VISA" = "visa",
}
export type UserOrder = {
  address: Omit<AddressFormType, "phoneNumber">;
  orderStatus: OrderStatusType;
  paymentMethod: PaymentMethodType;
  products: {
    product: Products;
    count: number;
  }[];
  totalPrice: number;
  orderDate: Date;
  _id: string;
};
