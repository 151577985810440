import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import { CarMake } from "../../util/types";
import { useUpdateSearchParams } from "../../hooks/useUpdateSearchParams";
import { pagination } from "../state/productsSlice";
import { useAppDispatch } from "../../hooks/useAppDispatchSelector";

export default function MultiSelectAccordion({
  carMakeData,
}: {
  carMakeData: CarMake[];
}) {
  const [openMake, setOpenMake] = useState<string | null>(null);
  const [searchParams] = useSearchParams();

  // Initialize selected models state from URL parameters
  const [selectedModels, setSelectedModels] = useState<{
    [key: string]: Set<string>;
  }>(() => {
    const makeFromUrl = searchParams.get("make");
    const modelsFromUrl = searchParams.get("model")?.split(",") || [];

    if (makeFromUrl && modelsFromUrl.length > 0) {
      return {
        [makeFromUrl.toUpperCase()]: new Set(
          modelsFromUrl.map((m) => m.toUpperCase())
        ),
      };
    }
    return {};
  });

  const updateParams = useUpdateSearchParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const handler = setTimeout(() => {
      const params: Record<string, string | null> = {};

      const selectedEntries = Object.entries(selectedModels).filter(
        ([, modelSet]) => modelSet.size > 0
      );

      if (selectedEntries.length > 0) {
        selectedEntries.forEach(([make, modelSet]) => {
          params.make = make.toLowerCase();
          params.model = Array.from(modelSet).join(",").toLowerCase();
        });
      } else {
        params.make = null;
        params.model = null;
      }

      updateParams(params);
    }, 500);

    return () => clearTimeout(handler);
  }, [selectedModels]);
  useEffect(() => {
    if (!window.location.search) {
      setSelectedModels({});
    }
  }, [window.location.search]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        pagination({
          pageNumber: 1,
        })
      );
    }, 500);

    return () => clearTimeout(timeout);
  }, [selectedModels]);
  const toggleMake = (make: string) => {
    setOpenMake(openMake === make ? null : make);
  };

  const toggleModel = (make: string, model: string) => {
    setSelectedModels((prev) => {
      const newSelection = { ...prev };
      if (!newSelection[make]) newSelection[make] = new Set();

      if (newSelection[make].has(model)) {
        newSelection[make].delete(model);
      } else {
        newSelection[make].add(model);
      }

      return { ...newSelection };
    });
  };

  const toggleMakeSelection = (make: string) => {
    setSelectedModels((prev) => {
      const newSelection = { ...prev };
      const allModels =
        carMakeData.find((item) => item.make === make)?.models || [];

      if (newSelection[make]?.size === allModels.length) {
        delete newSelection[make]; // Deselect all
      } else {
        newSelection[make] = new Set(allModels); // Select all
      }

      return { ...newSelection };
    });
  };

  return (
    <div className="mx-auto">
      {carMakeData.map((item) => {
        const selectedCount = selectedModels[item.make]?.size || 0;
        const isChecked = selectedCount > 0;
        return (
          <div
            key={item.make}
            className="border border-gray-300 rounded-lg p-3 mb-2"
          >
            <div
              className="flex justify-between items-center cursor-pointer p-2"
              onClick={() => toggleMake(item.make)}
            >
              <div className="flex items-center gap-4">
                <img src={item.logo} alt={item.make} className="w-8 h-8" />
                <span className="text-2xl">{item.make}</span>
              </div>
              <Checkbox
                checked={isChecked}
                onChange={() => toggleMakeSelection(item.make)}
                onClick={(e) => e.stopPropagation()}
                className="ml-auto mr-5"
              />
              <DownOutlined
                className={`transition-transform ${
                  openMake === item.make ? "rotate-180" : ""
                }`}
              />
            </div>

            {openMake === item.make && (
              <div className="pl-6 border-l border-gray-300 mt-2">
                <p className="text-xl text-gray-600 mb-2">Model</p>
                {item.models.map((model) => {
                  return (
                    <label
                      key={model}
                      className="flex items-center gap-2 p-4 border border-gray-300 rounded-md mb-2 cursor-pointer hover:bg-gray-100"
                    >
                      <img src={item.logo} alt={model} className="w-6 h-6" />
                      <span className="capitalize text-xl">
                        {model.toLocaleLowerCase()}
                      </span>
                      <Checkbox
                        checked={selectedModels[item.make]?.has(model) || false}
                        onChange={() => toggleModel(item.make, model)}
                        className="ml-auto"
                      />
                    </label>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
