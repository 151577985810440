import React, { useEffect } from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import { Outlet, useLocation, useParams } from "react-router-dom";
import ProductsPagesLayout from "./productsPagesLayout";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../state/store";
import { pagination, resetProductDetails } from "../state/productsSlice";

function Layout() {
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (params.id === undefined) {
      dispatch(resetProductDetails());
    }
  }, [params]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);
  useEffect(() => {
    if (!location.pathname.startsWith("/products")) {
      dispatch(pagination({ pageNumber: 1 }));
      sessionStorage.setItem("pageNumber", "1");
    }
  }, [location.pathname]);

  if (
    location.pathname === "/" ||
    params.id ||
    params.confirmationCode ||
    location.pathname === "/about-us" ||
    location.pathname === "/our-service"
  )
    return (
      <div className="flex flex-col min-h-screen">
        <Header />
        <Outlet />
        <div className="mt-auto mb-0">
          <Footer />
        </div>
      </div>
    );
  else if (
    location.pathname === "/cart" ||
    location.pathname === "/checkout" ||
    location.pathname === "/orders" ||
    location.pathname === "/reset-password" ||
    location.pathname === "/forget-password" ||
    location.pathname === "/policy" ||
    location.pathname === "/estimation-request" ||
    location.pathname === "/part-request"
  ) {
    return (
      <div className="flex flex-col min-h-screen">
        <Header />
        <div className="pt-52 pb-32 px-10">
          <div className="container mx-auto">
            <div className="lg:w-[60%] mx-auto">
              <Outlet />
            </div>
          </div>
        </div>
        <div className="mt-auto mb-0">
          <Footer />
        </div>
      </div>
    );
  } else
    return (
      <div className="flex flex-col min-h-screen">
        <Header isCarListing={true} />
        <ProductsPagesLayout />
        <div className="mt-auto mb-0">
          <Footer />
        </div>
      </div>
    );
}

export default Layout;
