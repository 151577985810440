import { productsReducer } from "./productsSlice";

import { adminProductsReducer } from "./adminProductsSlice";
import { configureStore } from "@reduxjs/toolkit";
import { categoriesReducer } from "./categoriesSlice";
import { successReducer } from "./successSlice";
import { errorReducer } from "./errorSlice";
import { adminAuthenticationReducer } from "./adminAuthSlice";
import { userAuthenticationReducer } from "./userAuthSlice";
import { makeModelReducer } from "./adminMakeModelsSlice";
import { CartReducer } from "./cartSlice";
import { checkoutReducer } from "./checkoutSlice";
import { userOrderReducer } from "./userOrdersSlice";
import { adminResetPasswordReducer } from "./adminResetPasswordSlice";
import { userResetPasswordReducer } from "./userResetPasswordSlice";

import { estimationsReducer } from "./adminEstimationSlice";
import { userEstimationReducer } from "./userEstimationSlice";
import { userRequestPartReducer } from "./userRequestPartsSlice";

// import { addProductReducer } from "./addProductSlice";

export const store = configureStore({
  reducer: {
    errorReducer,
    adminAuthenticationReducer,
    // addProductReducer,
    successReducer,
    categoriesReducer,
    adminProductsReducer,
    adminResetPasswordReducer,
    productsReducer,
    userAuthenticationReducer,
    makeModelReducer,
    CartReducer,
    checkoutReducer,
    userEstimationReducer,
    userOrderReducer,
    userResetPasswordReducer,
    userRequestPartReducer,
    estimationsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
