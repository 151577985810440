import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreateOrderRequest, OrderResponse } from "../../util/types";
import { fetchRequest } from "../../util/util";
import { resetCart } from "./cartSlice";

type CheckoutInitialState = {
  loadingResponse: boolean;
  orderCreated: boolean | null;
  showThankyou: boolean;
};
const initialState: CheckoutInitialState = {
  orderCreated: null,
  loadingResponse: false,
  showThankyou: false,
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState: initialState,
  reducers: {
    showThankYou: (state) => {
      state.showThankyou = true;
    },
    resetCheckout: (state) => {
      state.showThankyou = false;
      state.orderCreated = null;
      state.loadingResponse = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createOrderThunk.pending, (state) => {
      state.loadingResponse = true;
    });
    builder.addCase(
      createOrderThunk.fulfilled,
      (state, action: PayloadAction<OrderResponse>) => {
        state.loadingResponse = false;
        if (action.payload.error) {
          state.orderCreated = false;
          return;
        }
        state.orderCreated = true;
      }
    );
    builder.addCase(createOrderThunk.rejected, (state) => {
      state.loadingResponse = false;
      state.orderCreated = false;
    });
  },
});

export const createOrderThunk = createAsyncThunk(
  "products/checkout",
  async (
    { orderDetails }: { orderDetails: CreateOrderRequest },
    { dispatch }
  ) => {
    const data: OrderResponse = await fetchRequest<OrderResponse>({
      url: `${process.env.REACT_APP_BACKEND_DOMAIN}/createUserOrder`,
      method: "POST",
      body: { orderDetails, },
      dispatch,
    });
    if (data.success && !data.error) {
      dispatch(showThankYou());
      dispatch(resetCart());
    }
    return data;
  }
);

export const { showThankYou, resetCheckout } = checkoutSlice.actions;
export const checkoutReducer = checkoutSlice.reducer;
