export enum BackendStringResponses {
  EMAIL_ERROR = "EMAIL_ERROR",
  MAKE_ERROR = "MAKE_ERROR",
  MODEL_ERROR = "MODEL_ERROR",
  PASSWORD__ERROR = "PASSWORD_ERROR",
  ACTIVATION_ERROR = "ACTIVATION_ERROR",
  EXPIRED_OTP = "EXPIRED_OTP",
  INVALID_OTP = "INVALID_OTP",
  INVALID_EMAIL_FORMAT = "INVALID_EMAIL_FORMAT",
  PASSWORD_MISMATCH = "PASSWORD_MISMATCH",
  SHORT_PASSWORD = "SHORT_PASSWORD",
  EMPTY_USERNAME = "EMPTY_USERNAME",
  USER_EXISTS = "USER_EXISTS",
  USER_CREATED_WITHOUT_ACTIVATION = "USER_CREATED_WITHOUT_ACTIVATION",
  USER_LOGGED_IN = "USER_LOGGED_IN",
  INVALID_RESET_TOKEN = "INVALID_RESET_TOKEN",
  FORGET_EMAIL_NOT_EXIST = "FORGET_EMAIL_NOT_EXIST",
  FORGET_PROVIDE_EMAIL = "FORGET_PROVIDE_EMAIL",
  NAME_ERROR = "NAME_ERROR",
  DESCRIPTION_ERROR = "DESCRIPTION_ERROR",
  PHONENUMBER_ERROR = "PHONENUMBER_ERROR",
}
