import { Link } from "react-router-dom";
import "./styles/home.css";
import { Button } from "antd";
export default function HomeFirstSection() {
  return (
    <>
      {/* first section */}
      <div className="home_image">
        <div className="absolute top-0 w-screen h-full bg-gray_primary opacity-35" />
      </div>
      <div className="w-full absolute h-full top-0 right-0 left-0 px-10">
        <div className="flex h-full container mx-auto flex-col justify-center md:gap-7 gap-2 w-full items-center">
          <h1 className="z-10 text-[28px] md:text-7xl text-white  text-center uppercase font-semibold">
            Parts you need
          </h1>
          <h1 className="z-10 text-[28px] md:text-7xl text-white  text-center uppercase font-semibold">
            Speed you deserve
          </h1>
          <div className="flex flex-col gap-5">
            <Link
              to={"/products/allproducts"}
              className="w-[85vw] sm:w-[220px] mt-4 md:mt-0"
            >
              <Button className="bg-red_primary hover:!bg-red_primary w-full text-white hover:!text-white text-3xl border-red_primary hover:!border-red_primary px-10 h-max py-2 ">
                Shop Now
              </Button>
            </Link>
            <Link
              to={"/part-request"}
              className="w-[85vw] sm:w-[220px] mt-4 md:mt-0"
            >
              <Button className="bg-gray_primary hover:!bg-gray_primary w-full text-white hover:!text-white text-3xl border-gray_primary hover:!border-gray_primary px-10 h-max py-2 ">
                Request Part
              </Button>
            </Link>
          </div>

          {/* <div className="flex sm:flex-row flex-col gap-10">
            <Link to={"/products/allproducts"} className="w-[85vw] sm:w-max">
              <Button className="shopButton w-full text-white text-3xl ">
                Shop Now
              </Button>
            </Link>
            <Link to={"/part-request"} className="w-[85vw] sm:w-max">
              <Button className="shopButton w-full text-white text-3xl ">
                Request Part
              </Button>
            </Link>
          </div> */}
          {/* <Search
            placeholder="Search for items"
            className={"searchButton "}
            enterButton="Search"
          />
        </div> */}
        </div>
      </div>
    </>
  );
}
