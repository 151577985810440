import { LazyLoadImage } from "react-lazy-load-image-component";
import aboutUsMain from "../../assets/aboutUsMain.jpeg";
import whyGfast from "../home/images/why-gfast.jpg";
import mission from "../../assets/mission.svg";
import vision from "../../assets/vision.svg";
import { Divider } from "antd";
import HomeImageSection from "../home/HomeImageSection";
const AboutUs = () => {
  return (
    <div className="relative top-0 left-0 z-10">
      <div className="w-screen h-screen relative flex items-center justify-center flex-col">
        <LazyLoadImage
          src={aboutUsMain}
          alt="main-image"
          className="h-full w-full absolute left-0 top-0 object-cover"
        />
        <div className="w-full h-full bg-[#393E51] opacity-35 top-0 left-0 absolute" />
        <div className=" flex flex-col gap-2 z-10 relative container mx-auto w-max ">
          <p className="uppercase text-white_primary md:text-[40px] text-5xl font-bold">
            About G-Fast
          </p>
        </div>
      </div>
      <div className="container mx-auto bg-white">
        <div className="md:px-20 px-10 md:py-32 py-20">
          <p className="md:text-5xl text-4xl text-red_primary font-bold text-center md:text-left mb-4 md:mb-0">
            About Us
          </p>
          <div className="flex lg:flex-row flex-col lg:gap-5 gap-10 items-center">
            <div className="flex flex-col 2xl:gap-14 gap-8  w-full lg:w-2/3 md:text-2xl text-xl 2xl:text-3xl md:mt-14 mt-5 md:font-semibold text-gray_primary md:pr-10  md:text-left text-center">
              <p className="text-gray_primary md:text-2xl text-xl leading-8 md:leading-[25px]">
                At G-FAST, we simplify the automotive repair process with a
                seamless online platform for purchasing high-quality spare
                parts. Initially specializing in MG vehicles from 2018 onwards,
                we help owners restore their cars quickly and efficiently.
              </p>
              <p className="text-gray_primary md:text-2xl text-xl leading-8 md:leading-[25px]">
                Our platform ensures transparent pricing with no hidden costs,
                backed by strict warranty and return policies. Customers receive
                expert guidance on spare parts and body repair, making informed
                purchasing decisions easier than ever.
              </p>
              <p className="text-gray_primary md:text-2xl text-xl leading-8 md:leading-[25px]">
                We balance quality and affordability while offering aftersales
                and technical support. Services include free accident analysis
                reports and easy part requests, ensuring a smooth and efficient
                repair experience.
              </p>
            </div>
            <div className="lg:w-1/3 w-full">
              <LazyLoadImage
                className="object-fill  rounded-lg shadow-xl"
                src={whyGfast}
                alt="about us"
              />
            </div>
          </div>
        </div>
        <div className="w-[90%] mx-auto">
          <Divider className="!m-0" />
        </div>
        <div className="md:py-32 py-20">
          <p className="text-center md:mb-10 mb-24 md:text-5xl text-4xl font-bold text-red_primary capitalize">
            Our target & vision
          </p>
          <div className="flex md:flex-row flex-col gap-24 ">
            <div className="flex flex-col gap-5 items-center text-2xl w-full px-10">
              <img src={mission} alt="mission" className="w-[60px] h-[60px]" />
              <p className="font-bold text-4xl mb-4 text-red_primary">
                Mission
              </p>
              <p className="text-center md:w-2/3 text-gray_primary md:text-2xl text-xl leading-8 md:leading-[25px]">
                Our mission is to provide vehicle owners with a transparent,
                efficient, and supportive platform for acquiring high-quality
                spare parts. We aim to simplify the repair process, offering
                comprehensive guidance and swift solutions to ensure customer
                vehicles are restored to their best condition promptly.
              </p>
            </div>
            <div className="flex flex-col gap-5 items-center text-2xl w-full px-10">
              <img src={vision} alt="vision" className="w-[60px] h-[60px]" />
              <p className="font-bold text-4xl mb-4 text-red_primary">Vision</p>
              <p className="text-center md:w-2/3 text-gray_primary md:text-2xl text-xl leading-8 md:leading-[25px]">
                We envision becoming the leading online destination for
                automotive spare parts, renowned for our commitment to quality,
                transparency, and exceptional customer support. As we expand our
                offerings to include brands such as Fiat, Hyundai, and Kia, we
                aim to support a broader range of vehicle owners in maintaining
                and restoring their vehicles with confidence.
              </p>
            </div>
          </div>
        </div>
        <div className="w-[90%] mx-auto">
          <Divider className="!m-0" />
        </div>
        <div className="md:py-32 py-20">
          <div className="flex gap-14">
            <div className="w-1/3 hidden md:block">
              <LazyLoadImage
                className="object-fill  rounded-lg shadow-xl"
                src={whyGfast}
                alt="about us"
              />
            </div>
            <div className="md:w-2/3 w-full flex flex-col items-center md:items-start gap-10 px-10">
              <p className="capitalize text-red_primary text-5xl font-bold">
                Why choose us
              </p>
              <div className="md:flex justify-between hidden">
                <div className="flex flex-col gap-3 w-full">
                  <div className="flex-col text-2xl w-[70%] ">
                    <p className="font-semibold mb-2">
                      1- Complete Transparency
                    </p>
                    <p className="text-gray_primary text-2xl leading-8 md:leading-[25px]">
                      No hidden costs, instant price access.
                    </p>
                  </div>
                  <div className="flex-col text-2xl w-[70%] ">
                    <p className="font-semibold mb-2">
                      3- Fast 24-Hour Delivery
                    </p>
                    <p className="text-gray_primary text-2xl leading-8 md:leading-[25px]">
                      Quick turnaround time for all orders
                    </p>
                  </div>
                  <div className="flex-col text-2xl w-[70%] ">
                    <p className="font-semibold mb-2">5- Warranty & Returns</p>
                    <p className="text-gray_primary text-2xl leading-8 md:leading-[25px]">
                      Secure purchases with strict quality control.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-3 w-full">
                  <div className="flex-col text-2xl w-[70%] ">
                    <p className="font-semibold mb-2">
                      2- High-Quality Spare Parts
                    </p>
                    <p className="text-gray_primary text-2xl leading-8 md:leading-[25px]">
                      Genuine and cost-effective options.
                    </p>
                  </div>
                  <div className="flex-col text-2xl w-[70%] ">
                    <p className="font-semibold mb-2">
                      4- Technical & Aftersales Support
                    </p>
                    <p className="text-gray_primary text-2xl leading-8 md:leading-[25px]">
                      Expert guidance throughout
                    </p>
                  </div>
                  <div className="flex-col text-2xl w-[70%] ">
                    <p className="font-semibold mb-2">
                      6- Accident Analysis Reports
                    </p>
                    <p className="text-gray_primary text-2xl leading-8 md:leading-[25px]">
                      Free assessments for damaged vehicles
                    </p>
                  </div>
                </div>
              </div>
              <div className="md:hidden flex flex-col gap-10 items-center">
                <div className="flex-col text-2xl text-center items-center">
                  <p className="font-semibold mb-2">1- Complete Transparency</p>
                  <p className="text-gray_primary text-2xl leading-8 md:leading-[25px]">
                    No hidden costs, instant price access.
                  </p>
                </div>
                <div className="flex-col text-2xl text-center items-center">
                  <p className="font-semibold mb-2">
                    2- High-Quality Spare Parts
                  </p>
                  <p className="text-gray_primary text-2xl leading-8 md:leading-[25px]">
                    Genuine and cost-effective options.
                  </p>
                </div>
                <div className="flex-col text-2xl text-center items-center">
                  <p className="font-semibold mb-2">3- Fast 24-Hour Delivery</p>
                  <p className="text-gray_primary text-2xl leading-8 md:leading-[25px]">
                    Quick turnaround time for all orders
                  </p>
                </div>
                <div className="flex-col text-2xl text-center items-center">
                  <p className="font-semibold mb-2">
                    4- Technical & Aftersales Support
                  </p>
                  <p className="text-gray_primary text-2xl leading-8 md:leading-[25px]">
                    Expert guidance throughout
                  </p>
                </div>
                <div className="flex-col text-2xl text-center items-center">
                  <p className="font-semibold mb-2">5- Warranty & Returns</p>
                  <p className="text-gray_primary text-2xl leading-8 md:leading-[25px]">
                    Secure purchases with strict quality control.
                  </p>
                </div>
                <div className="flex-col text-2xl text-center items-center">
                  <p className="font-semibold mb-2">
                    6- Accident Analysis Reports
                  </p>
                  <p className="text-gray_primary text-2xl leading-8 md:leading-[25px]">
                    Free assessments for damaged vehicles
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p className="md:text-[18px] text-xl mt-14 md:px-0 px-10 text-center md:text-left text-gray_primary md:leading-[25px] font-semibold">
            At G-FAST, we don’t just sell spare parts—we provide solutions,
            guidance, and reliability to every vehicle owner. Whether you need a
            single replacement part or are looking to partner with us for bulk
            orders, we are your trusted partner in automotive spare parts.
          </p>
        </div>
      </div>
      <HomeImageSection
        text1="Your car deserves the best, "
        text2="Choose G-fast"
      />
    </div>
  );
};
export default AboutUs;
