import "./styles/home.css";
import HomeFirstSection from "./homeFirstSection";
import HomeSecondSection from "./homeSecondSection";
import HomeThirdSection from "./homeThirdSection";
import HomeFourthSection from "./homeFourthSection";
import { useEffect, useState } from "react";
import { WhatsAppOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import AboutUsSection from "./aboutUsSection";
import HomeImageSection from "./HomeImageSection";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
const handleWhatsAppClick = () => {
  const phoneNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
  const message = `Hello, I am asking about your service`;
  const encodedMessage = encodeURIComponent(message);
  const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;
  window.open(whatsappUrl, "_blank");
};

export default function Home() {
  return (
    <>
      <Tooltip
        placement="left"
        title="Contact us on whatsapp"
        className="sm:block hidden"
      >
        <div
          className="fixed sm:bottom-[400px] sm:right-24 z-30 bg-white p-4 sm:p-5 rounded-full shadow-xl cursor-pointer"
          onClick={() => {
            handleWhatsAppClick();
          }}
        >
          <WhatsAppOutlined className="text-whatsap_color sm:text-5xl text-3xl" />
        </div>
      </Tooltip>
      <div
        className="fixed bottom-10 right-10 z-30 bg-white p-4 rounded-full shadow-xl cursor-pointer sm:hidden"
        onClick={() => {
          handleWhatsAppClick();
        }}
      >
        <WhatsAppOutlined className="text-whatsap_color text-6xl" />
      </div>

      <HomeFirstSection />
      <AboutUsSection />

      <HomeSecondSection />
      <HomeThirdSection />
      <div className="bg-white z-10">
        <HomeFourthSection />
        <HomeImageSection />
      </div>
    </>
  );
}
