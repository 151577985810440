import { Button, Form, Select } from "antd";
import {
  useAppDispatch,
  useAppSelector,
} from "../../hooks/useAppDispatchSelector";
import { AddressFormType } from "../../util/types";
import { createOrderThunk, resetCheckout } from "../state/checkoutSlice";
import { CheckOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const Checkout = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.userAuthenticationReducer.user);
  const orderCreated = useAppSelector(
    (state) => state.checkoutReducer.orderCreated
  );
  const showThankyou = useAppSelector(
    (state) => state.checkoutReducer.showThankyou
  );
  const loadingResponse = useAppSelector(
    (state) => state.checkoutReducer.loadingResponse
  );
  const dispatch = useAppDispatch();
  const totalPrice = useAppSelector((state) => state.CartReducer.totalPrice);
  const cartItems = useAppSelector((state) => state.CartReducer.cartItems);
  useEffect(() => {
    if (!cartItems.length && !showThankyou) {
      navigate("/cart");
    }
  }, [cartItems]);
  useEffect(() => {
    dispatch(resetCheckout());
  }, []);
  const [addressForm] = Form.useForm();
  const handleSubmitOrder = () => {
    addressForm.validateFields().then(async (values: AddressFormType) => {
      const orderProducts = cartItems.map((item) => ({
        product: item.product._id,
        count: item.count,
      }));

      dispatch(
        createOrderThunk({
          orderDetails: {
            address: values,
            order: orderProducts,
          },
        })
      );
    });
  };
  return (
    <div>
      {orderCreated === null && (
        <>
          <div className="flex flex-col justify-between mt-10 border rounded-2xl p-5 border-dashed gap-10">
            <div className="flex flex-col gap-5 border-b border-dashed pb-10">
              <p className="text-3xl font-semibold capitalize">
                order summary:
              </p>
              {cartItems.map((item, index) => (
                <div className="flex items-center justify-between" key={index}>
                  <p className="text-2xl">{item.product.name}</p>
                  <p className="text-2xl">
                    {item.count} x{" "}
                    {item.product.hasDiscount
                      ? item.product.discountPrice
                      : item.product.price}{" "}
                    EGP
                  </p>
                </div>
              ))}
            </div>
            <div className="flex justify-between items-center">
              <p className="text-2xl font-semibold capitalize">
                Delievery Fees
              </p>
              <p className="text-2xl font-semibold">Free</p>
            </div>
            <div className="flex justify-between items-center">
              <p className="text-2xl font-semibold capitalize">total order</p>
              <p className="text-2xl font-semibold">{totalPrice} EGP</p>
            </div>
          </div>
          <Form
            onFinish={handleSubmitOrder}
            form={addressForm}
            className="flex flex-col justify-between mt-10 border rounded-2xl p-5 border-dashed w-full "
          >
            <div className="flex flex-col gap-5 pb-10">
              <p className="text-3xl font-semibold capitalize">
                Delivery Details
              </p>
            </div>
            <div className="flex lg:flex-row flex-col lg:gap-10 w-full justify-between ">
              <Form.Item
                labelCol={{ span: 24 }}
                name={"city"}
                className="flex-1"
                label="City/المدينه"
                required
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "City field is required ",
                  },
                ]}
              >
                <Select
                  placeholder="Select city"
                  className="hover:!border-none"
                  options={[
                    { value: "cairo", label: "Cairo" },
                    {
                      value: "giza",
                      label: "Giza",
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 24 }}
                name={"area"}
                className="flex-1"
                label="Area/المنطقه"
                required
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && value.trim().length !== 0) {
                        // If the value is not empty and doesn't contain any whitespace
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Please enter a valid product name")
                      );
                    },
                    type: "string",
                    required: true,
                    message: "Area field is required ",
                  },
                ]}
              >
                <input
                  type="text"
                  className="border p-3 rounded-lg focus:outline-none w-full text-[16px] sm:text-2xl"
                  placeholder="area"
                />
              </Form.Item>
            </div>
            <div className="flex lg:flex-row flex-col lg:gap-10 w-full justify-between">
              <Form.Item
                labelCol={{ span: 24 }}
                name={"phoneNumber"}
                className="flex-1"
                label="Phone Number/رقم الهاتف"
                required
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Phone Number field is required ",
                  },
                ]}
              >
                <input
                  type="number"
                  className="border p-3 rounded-lg focus:outline-none w-full text-[16px] sm:text-2xl"
                  placeholder="Phone number"
                />
              </Form.Item>
              {/* just plaseholder for spacing */}
              <div className="flex-1"></div>
            </div>
            <div className="flex lg:flex-row flex-col lg:gap-10 w-full justify-between">
              <Form.Item
                labelCol={{ span: 24 }}
                name={"detailedAddress"}
                className="flex-1"
                label="Detailed address/العنوان بالتفصيل"
                required
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && value.trim().length !== 0) {
                        // If the value is not empty and doesn't contain any whitespace
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Please enter a valid product name")
                      );
                    },
                    type: "string",
                    required: true,
                    message: "Detailed address field is required ",
                  },
                ]}
              >
                <textarea
                  className="border p-3 rounded-lg focus:outline-none w-full text-[16px] sm:text-2xl"
                  placeholder="Detailed Address"
                  style={{
                    resize: "none",
                  }}
                  maxLength={500}
                  rows={4}
                />
              </Form.Item>
            </div>
            <Button
              loading={loadingResponse}
              onClick={() => {
                addressForm.submit();
              }}
              className="bg-blue_primary text-white flex items-center lg:justify-between justify-center gap-3 w-full lg:w-max lg:ml-auto lg:mx-[unset] mx-auto py-8 lg:py-[unset]"
            >
              <p>Submit Order</p>
            </Button>
          </Form>
        </>
      )}
      {!loadingResponse && orderCreated && (
        <div className="flex flex-col items-center bg-gray-50 rounded-lg py-10 gap-5  mx-auto mt-44">
          <div className="flex items-center justify-center border p-3 rounded-full border-blue_primary">
            <CheckOutlined className="text-5xl text-blue_primary" />
          </div>
          {/* <p className="text-2xl font-semibold">Hello</p> */}
          <p className="text-4xl font-semibold capitalize">
            Your order is confirmed
          </p>
          {/* <p className="text-2xl font text-center">
          We will send you a confirmation email and we will contact you soon
        </p> */}
          <p className="text-2xl font text-center">We will contact you soon</p>
          {/* <p className="text-2xl text-center font-semibold">
          You can track your order status from here
        </p> */}
          {/* <Button
          onClick={() => {
            navigate("/orders");
          }}
          className="bg-blue_primary text-white flex items-center justify-between gap-3 w-max "
        >
          <p>My Orders</p>
        </Button> */}
          <Button
            onClick={() => {
              navigate("/products/allproducts");
            }}
            className="bg-blue_primary text-white flex items-center justify-between gap-3 w-max "
          >
            <p>Products</p>
          </Button>
        </div>
      )}
    </div>
  );
};

export default Checkout;
