import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  capitalizeWord,
  formatMakeAndModel,
  formatYearString,
} from "../../util/util";
import "./styles/productsCard.css";
import { Button, Popover } from "antd";
import { ShoppingCartOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../hooks/useAppDispatchSelector";
import { Products } from "../state/productsSlice";
import { addToCart } from "../state/cartSlice";

type ProductCardProps = {
  imageSource: string;
  targetUrl?: string;
};

export default function ProductCard({
  imageSource,
  targetUrl = undefined,
  product,
}: { product: Products } & ProductCardProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    category,
    _id: id,
    name: productName,
    make,
    model,
    yearFrom,
    yearTo,
    discountPrice,
    productQuality,
    price,
    hasDiscount,
  } = product;
  const categories = category.slice(0, 2);
  const handleWhatsAppClick = (id: string) => {
    const phoneNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
    let message = `Hello, I am asking about this product: ${window.location.href}/${id}`;
    if (location.pathname === "/") {
      message = `Hello, I am asking about this product: ${window.location.href}${category[0]}/${id}`;
    }
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;
    window.open(whatsappUrl, "_blank");
  };
  const dispatch = useAppDispatch();
  let redirectedURL: string;

  if (location.pathname === "/") {
    redirectedURL = `/products/${category[0]}/${id}`;
  } else if (targetUrl) {
    redirectedURL = targetUrl;
  } else {
    redirectedURL = `${location.pathname}/${id}`;
  }

  return (
    <div className="flex flex-col w-full h-full justify-between border border-transparent md:hover:scale-[1.02] duration-300 rounded-lg  cursor-pointer bg-blue_primary p-3 font-medium">
      <Link to={redirectedURL} className="flex flex-col relative">
        <div className="h-96 mb-5 relative overflow-hidden ">
          <LazyLoadImage
            src={imageSource}
            effect="blur"
            width={"100%"}
            height={"100%"}
            className="object-cover w-full h-full aspect-square rounded-lg"
          />
          {hasDiscount && (
            <div className="rotate-45 bg-blue_primary  flex justify-center items-center saleBox top-[20px] -right-[35px] absolute">
              <p className="text-white text-2xl ml-3">
                {discountPrice &&
                  (((price - discountPrice) / price) * 100).toFixed(0) +
                    "% Sale"}
              </p>
            </div>
          )}
          <div className="absolute top-0 left-0 bg-blue_primary  h-10 rounded-br-lg flex items-center justify-center px-4">
            <p className="text-white font-semibold text-lg">
              {capitalizeWord(productQuality?.replaceAll("_", " "))}
            </p>
          </div>
        </div>
      </Link>
      <div>
        <Link to={redirectedURL} className="flex flex-col relative">
          <div className="text-white_primary">
            <p className="text-2xl mb-2 font-semibold text-center md:text-start">
              {capitalizeWord(productName)} /{" "}
              {formatMakeAndModel({ make, model })}
            </p>
            <div>
              <p className="text-xl capitalize mb-2 md:text-start text-center">
                {formatYearString({ yearFrom, yearTo })}
              </p>
              <div className="flex gap-2 md:justify-start justify-center">
                {categories.map((category, i) => {
                  if (i > 1) return null;
                  else if (categories.length - i - 1 === 0) {
                    return (
                      <p key={i} className="text-xl capitalize mb-2 ">
                        {category}
                      </p>
                    );
                  } else
                    return (
                      <p key={i} className="text-xl capitalize mb-2 ">
                        {category},
                      </p>
                    );
                })}
              </div>
              <div className="font-semibold flex gap-2 text-2xl capitalize w-full justify-center md:justify-start">
                {discountPrice && (
                  <div className="flex gap-2 ">
                    <p>{discountPrice}</p>
                    <p>EGP</p>
                  </div>
                )}
                <div
                  className={
                    discountPrice
                      ? "flex gap-2 line-through text-gray-400"
                      : "flex gap-2"
                  }
                >
                  <p>{price}</p>
                  <p>EGP</p>
                </div>
              </div>
            </div>
          </div>
        </Link>
        <div className="flex justify-between gap-5">
          <Button
            className="flex mt-5 items-center justify-center gap-5 bg-white_primary flex-1 font-medium rounded-lg "
            onClick={() => {
              dispatch(
                addToCart({
                  product: {
                    count: 1,
                    product,
                  },
                })
              );
            }}
          >
            Add to cart
            <ShoppingCartOutlined className="text-3xl" />
          </Button>

          <Popover
            placement="top"
            content={<p>Ask on Whatsapp</p>}
            className="hidden sm:flex"
          >
            <Button
              className="mt-5 items-center justify-center gap-3 bg-white_primary w-max rounded-lg"
              onClick={() => {
                handleWhatsAppClick(id);
              }}
            >
              <WhatsAppOutlined className="text-hatsap_color mt-1" />
            </Button>
          </Popover>
          <Button
            className="flex mt-5 items-center justify-center gap-3 bg-white_primary w-max sm:hidden"
            onClick={() => {
              handleWhatsAppClick(id);
            }}
          >
            <WhatsAppOutlined className="text-hatsap_color mt-1" />
          </Button>
        </div>
        <Button
          className="flex mt-5 items-center justify-center gap-5 bg-white_primary border border-red_primary flex-1 font-medium rounded-lg w-full text-gray_primary"
          onClick={() => {
            dispatch(
              addToCart({
                product: {
                  count: 1,
                  product,
                },
              })
            );
            navigate("/cart");
          }}
        >
          Buy Now
        </Button>
      </div>
    </div>
  );
}
