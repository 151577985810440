import { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../hooks/useAppDispatchSelector";
import ProductCard from "../common/productCard";
import { useWindowDimensions } from "./home";
import { LoadingOutlined } from "@ant-design/icons";
import { getProductsThunk } from "../state/productsSlice";
export default function HomeThirdSection() {
  const { width } = useWindowDimensions();
  const dispatch = useAppDispatch();
  const products = useAppSelector(
    (state) => state.productsReducer.originalFilteredProducts
  );
  const loading = useAppSelector(
    (state) => state.productsReducer.loadingProducts
  );
  useEffect(() => {
    dispatch(
      getProductsThunk({
        category: "allProducts",
      })
    );
  }, []);

  return (
    <div className="relative z-20 bg-gray-50 h-max w-full md:py-40 py-20 px-10">
      <div className="container flex items-center flex-col mx-auto w-[85vw] sm:w-full">
        <h2 className="text-center text-4xl sm:text-7xl font-semibold w-full sm:w-max border-b-2 pb-5 border-b-blue_primary px-5 mb-20 md:mb-40 text-gray_primary">
          Featured Products
        </h2>
        <div className="grid grid-cols-1 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 container gap-10 ">
          {loading ? (
            <div className="flex items-end justify-center">
              <LoadingOutlined className="text-7xl" />
            </div>
          ) : (
            products?.map((product, i) => {
              if (width <= 640 && i > 3) {
                return null;
              } else if (width <= 768 && i > 7) {
                return null;
              } else if (width <= 1024 && i > 8) {
                return null;
              } else if (width <= 1280 && i > 7 && width > 1024) {
                return null;
              } else if (i > 9 && width > 1280) {
                return null;
              }
              return (
                <ProductCard
                  imageSource={product.coverPhoto.url}
                  key={product?._id}
                  product={product}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}
