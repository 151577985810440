import { useEffect, useRef } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Home from "./components/home/home";
import Layout from "./components/common/layout";
import AdminLogin from "./admin/login";
import AdminSignup from "./admin/createUser";
import ProtectedRoutes from "./util/protectedRoutes";
import { fethcingAdminCredentials } from "./components/state/adminAuthSlice";
import AdminLayout from "./components/common/adminLayout";
import AddRemoveCategories from "./admin/addRemoveCategories";
import AdminProducts from "./admin/adminProducts";
import AddEditProduct from "./admin/addProduct";
import Settings from "./admin/settings";
import SuperAdminRoutes from "./util/superAdminRoutes";
import { getProductsThunk } from "./components/state/productsSlice";
import ProductDetail from "./components/productDetails";
import FilteredProducts from "./components/common/filteredProducts";
import { fethcingUserCredentials } from "./components/state/userAuthSlice";
import { useAppDispatch, useAppSelector } from "./hooks/useAppDispatchSelector";
import Cart from "./components/cart/cart";
import Checkout from "./components/checkout/checkout";
import UserProtectedRoutes from "./util/userProtectedRoutes";
import Orders from "./components/orders/orders";
import ResetPasswordForm from "./components/resetPassword/resetPasswordForm";
import ForgetPasswordForm from "./components/resetPassword/forgetPasswordForm";
import PolicyAndWarranty from "./components/policy/policy";

import NotFound from "./components/notFound/notFound";
import UserSpecificPart from "./components/userEstimation/userRequestEstimation";
import AdminEstimations from "./admin/adminEstimations";
import AdminDetailedEstimation from "./admin/adminDetailedEstimation";
import AboutUs from "./components/abous-us";
import OurService from "./components/services";
import UserRequestPartForm from "./components/userEstimation/userRequestPart";

function App() {
  const location = useLocation();
  const adminPathname: boolean = location.pathname.split("/")[1] === "admin";
  let firstRender = useRef<boolean>(true);
  const isAuth = useAppSelector(
    (state) => state.adminAuthenticationReducer.authenticated
  );
  const dispatch = useAppDispatch();
  const loading = useAppSelector(
    (state) => state.adminAuthenticationReducer.loading
  );

  useEffect(() => {
    firstRender.current = false;
    if (adminPathname) {
      dispatch(fethcingAdminCredentials());
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!adminPathname) {
      dispatch(fethcingUserCredentials());
    }
  }, []);

  useEffect(() => {
    if (adminPathname) return;
  }, []);

  if (loading && adminPathname && firstRender.current) {
    return <div>Loading...</div>;
  }
  return (
    <Routes>
      {isAuth ? (
        <Route
          path="/admin/login"
          element={<Navigate to={"/admin/products"} />}
        />
      ) : (
        <Route path="/admin/login" element={<AdminLogin />} />
      )}
      <Route element={<ProtectedRoutes />}>
        <Route path="/admin" element={<Navigate to={"/admin/products"} />} />
        <Route path="/admin/signup" element={<AdminSignup />} />
        <Route element={<AdminLayout />}>
          <Route
            path={"/admin/dashboard"}
            element={<Navigate to={"/admin/products"} />}
          />
          {/* <Route path={"/admin/dashboard"} element={<AdminDashboard />} /> */}
          <Route
            path={"/admin/addProduct"}
            element={<AddEditProduct addOrEdit="add" />}
          />
          <Route path={"/admin/categories"} element={<AddRemoveCategories />} />
          <Route path={"/admin/products"} element={<AdminProducts />} />
          <Route path={"/admin/settings"} element={<Settings />} />
          <Route path={"/admin/estimations"} element={<AdminEstimations />} />
          <Route
            path={"/admin/estimations/:id"}
            element={<AdminDetailedEstimation />}
          />
        </Route>
      </Route>
      <Route element={<SuperAdminRoutes />}>
        <Route element={<AdminLayout />}>
          <Route path="/admin/createUser" element={<AdminSignup />} />
        </Route>
      </Route>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/our-service" element={<OurService />} />
        {/* <Route
          path="/activation/:confirmationCode"
          element={<EmailActivation />}
        /> */}
        <Route path="products/:category/:id" element={<ProductDetail />} />
        <Route path="/products/:category" element={<FilteredProducts />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/reset-password" element={<ResetPasswordForm />} />
        <Route path="/forget-password" element={<ForgetPasswordForm />} />
        <Route path="/policy" element={<PolicyAndWarranty />} />
        <Route path="/part-request" element={<UserRequestPartForm />} />
        <Route path="/estimation-request" element={<UserSpecificPart />} />

        <Route path="/checkout" element={<Checkout />} />
        <Route element={<UserProtectedRoutes />}>
          <Route path="/orders" element={<Orders />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
