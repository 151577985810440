import { faFacebook, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import instagram from "../../assets/instagram.svg";
type FooterRoutesType = {
  title: string;
  route: string;
  key: string;
  shouldAppearInCategories: boolean;
};
export const footerRoutes: FooterRoutesType[] = [
  {
    key: "allproducts",
    route: "/products/allproducts",
    title: "All Products",
    shouldAppearInCategories: true,
  },
  {
    key: "bodyparts",
    route: "/products/bodyparts",
    title: "Body Parts",
    shouldAppearInCategories: true,
  },

  {
    key: "lights",
    route: "/products/lights",
    title: "Lights",
    shouldAppearInCategories: true,
  },
  {
    key: "accessories",
    route: "/products/accessories",
    title: "Accessories",
    shouldAppearInCategories: true,
  },
  {
    key: "brakes",
    route: "/products/brakes",
    title: "Brakes",
    shouldAppearInCategories: true,
  },
  {
    key: "cooling",
    route: "/products/cooling",
    title: "Cooling",
    shouldAppearInCategories: true,
  },
  {
    key: "suspension",
    route: "/products/suspension",
    title: "Suspention",
    shouldAppearInCategories: true,
  },
  {
    key: "policy",
    route: "/policy",
    title: "Policy & Warranty",
    shouldAppearInCategories: false,
  },
  {
    key: "createEstimationRequest",
    route: "/estimation-request",
    title: "Request Estimation",
    shouldAppearInCategories: false,
  },
  {
    key: "createPartRequest",
    route: "/part-request",
    title: "Request Part",
    shouldAppearInCategories: false,
  },
];
export default function Footer() {
  const location = useLocation();

  return (
    <div
      className={`relative z-20 flex items-center flex-col ${
        location.pathname === "/" ? "bg-gray-50" : "bg-white"
      } h-max w-full  px-10`}
    >
      <div className="container mx-auto flex justify-between flex-col sm:flex-row items-center sm:items-start w-[85vw] sm:w-full sm:gap-0 gap-8 border-t py-10 mt-10">
        {" "}
        <div className="flex flex-col">
          <h2 className="sm:text-4xl text-2xl mb-10 capitalize font-semibold text-center">
            Follow Up
          </h2>
          <div className="flex justify-center gap-5 mb-10">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={"https://www.facebook.com/share/17iKrQJG1c/?mibextid=wwXIfr"}
            >
              <FontAwesomeIcon
                className="text-facebook_color"
                size="3x"
                icon={faFacebook}
              />
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={"https://www.instagram.com/gfast.eg?igsh=eGZoNnc4YWk5MnJy"}
            >
              <img
                src={instagram}
                alt="instagramm"
                className="w-14 -translate-y-1"
              />
            </Link>
            <div
              className="cursor-pointer"
              onClick={() => {
                const phoneNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
                const message = `Hello, I am asking about your service`;
                const encodedMessage = encodeURIComponent(message);
                const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;
                window.open(whatsappUrl, "_blank");
              }}
            >
              <FontAwesomeIcon
                className="text-whatsap_color"
                size="3x"
                icon={faWhatsapp}
              />
            </div>
          </div>
          <div className="text-xl mt-auto font-semibold text-center">
            <p>Copyright &#169;2024 G-FAST</p>
          </div>
        </div>{" "}
        <div className="flex flex-col ">
          <h2 className="sm:text-4xl text-2xl mb-8 sm:mb-10 font-semibold text-center">
            Our Services
          </h2>
          <div className="grid sm:grid-cols-1 grid-cols-2 gap-x-10 sm:w-full w-[85vw]">
            {footerRoutes.map((route, index) => (
              <Link
                key={route.key}
                className={`flex flex-col 
                  ${
                    index % 2 === 0
                      ? "items-center"
                      : "items-center sm:items-center"
                  } 
                justify-center hover:text-main_color capitalize mb-1 w-full`}
                to={route.route}
              >
                <div className="sm:text-2xl text-xl font-semibold text-center">
                  {route.title}
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="flex flex-col items-center sm:items-start">
          <img className="w-32 mt-4" src="/images/logo-red.svg" alt="logo" />
          <p className="z-10 sm:text-2xl text-xl font-semibold sm:text-left text-center capitalize">
            Parts you need, speed you deserve
          </p>
        </div>
      </div>
    </div>
  );
}
