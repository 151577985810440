import "./styles/home.css";
import bodyCardImage from "./images/body_card.jpeg";
import lightsCard from "./images/headlight_card.jpeg";
import accessoriesCard from "./images/accessories_card.jpeg";
import carCrash from "./images/car-crash.jpeg";
import HomeCard from "./home_card";
export default function HomeSecondSection() {
  return (
    <div className="relative z-20  px-10  w-full bg-[rgba(56,63,81,0.8)]">
      <div className=" md:py-40 py-20 container mx-auto flex flex-col items-center">
        <p className="mb-10 text-5xl font-semibold capitalize text-white">
          our services
        </p>
        <div className="flex xl:gap-20 gap-5 sm:flex-row flex-col lg:px-5 xl:px-0 w-[85vw] sm:w-full px-0">
          <HomeCard
            source={carCrash}
            title=" Fix My Car  - Get A Quote"
            buttonContent="Create Estiamtion"
            url="/estimation-request"
            backdrop="bg-[rgba(0,0,0,0.6)]"
          />
          <HomeCard
            source={bodyCardImage}
            title="Our Body Parts Collection"
            buttonContent="Shop Now"
            url="/products/bodyparts"
          />
          <HomeCard
            source={lightsCard}
            title="Our Headlights Collection"
            buttonContent="Shop Now"
            url="/products/lights"
          />
          <HomeCard
            source={accessoriesCard}
            title="Our Accessories Collection"
            buttonContent="Shop Now"
            url="/products/accessories"
          />
        </div>
      </div>
    </div>
  );
}
