import { LazyLoadImage } from "react-lazy-load-image-component";
import carImageFooter from "../common/styles/images/car-listing-image-2.jpeg";
const HomeImageSection = ({
  text1 = "parts you need ",
  text2 = "speed you deserve",
}: {
  text1?: string;
  text2?: string;
}) => {
  return (
    <div className="bg-black w-full h-[30vh] relative flex ">
      <div className="w-full h-full absolute top-0 bg-[#393e51ba] z-[1]" />
      <LazyLoadImage
        src={carImageFooter}
        alt="car"
        className="absolute object-cover object-bottom w-full h-full"
      />{" "}
      <div className="z-[1] md:mb-10 mb-auto mt-auto text-white_primary text-5xl container mx-auto relative capitalize text-center flex font-semibold px-10 md:px-0">
        <div className="md:mb-10  md:block flex flex-col md:justify-center mx-auto md:mx-0 md:text-5xl text-4xl">
          <span>{text1}</span>
          <span>{text2}</span>
        </div>
      </div>
    </div>
  );
};

export default HomeImageSection;
