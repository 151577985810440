import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Button } from "antd";
import { Link } from "react-router-dom";
type props = {
  source: string;
  title: string;
  buttonContent: string;
  url: string;
  backdrop?: string;
};
export default function HomeCard({
  source,
  title,
  buttonContent,
  url,
  backdrop = "bg-[rgba(0,0,0,0.4)]",
}: props) {
  return (
    <div className="w-full h-[30rem] sm:h-[35rem] lg:h-[40rem] relative ">
      <LazyLoadImage
        src={source}
        width={"100%"}
        height={"100%"}
        className=" object-cover w-full h-full aspect-square rounded-xl "
        effect="blur"
      />
      <div
        className={`${backdrop} w-full h-full absolute top-0 flex justify-end items-center flex-col  text-white py-20 text-center gap-10 rounded-xl`}
      >
        <p className="xl:text-4xl lg:text-3xl sm:text-2xl font-semibold text-3xl">
          {title}
        </p>
        <Link to={url}>
          <Button className="shopButton text-white text-3xl w-max ">
            {buttonContent}
          </Button>
        </Link>
      </div>
    </div>
  );
}
