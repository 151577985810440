import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderResponse } from "../../util/types";
import { fetchRequest } from "../../util/util";
import { resetCart } from "./cartSlice";
import { FileItem } from "./adminProductsSlice";
import { uploadMultipeImagesToS3 } from "../../util/adminUtil";

type CheckoutInitialState = {
  loadingResponse: boolean;
  requestPartCreated: boolean;
  showThankyou: boolean;
  errorMessage: string | null;
};
const initialState: CheckoutInitialState = {
  requestPartCreated: false,
  loadingResponse: false,
  showThankyou: false,
  errorMessage: null,
};

const userRequestPart = createSlice({
  name: "userRequestPart",
  initialState: initialState,
  reducers: {
    showThankYou: (state) => {
      state.showThankyou = true;
    },
    resetRequestParts: (state) => {
      state.showThankyou = false;
      state.requestPartCreated = false;
      state.loadingResponse = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userRequestPartThunk.pending, (state) => {
      state.loadingResponse = true;
    });
    builder.addCase(
      userRequestPartThunk.fulfilled,
      (state, action: PayloadAction<OrderResponse>) => {
        state.loadingResponse = false;
        if (action.payload?.error?.error?.message) {
          state.requestPartCreated = false;
          state.errorMessage = action.payload.error.error?.message;
          return;
        }
        state.requestPartCreated = true;
      }
    );
    builder.addCase(userRequestPartThunk.rejected, (state) => {
      state.loadingResponse = false;
      state.requestPartCreated = false;
    });
  },
});

export const userRequestPartThunk = createAsyncThunk(
  "user/userRequestPart",
  async (
    {
      name,
      email,
      description,
      phoneNumber,
      images,
    }: {
      name: string;
      email: string;
      description: string;
      phoneNumber: string;
      images: FileItem[];
    },
    { dispatch }
  ) => {
    const fileNames = images?.map((file) => file?.name);
    const uploadImages = async () => {
      if (!images?.length || !fileNames) return false;
      return await uploadMultipeImagesToS3({
        fileNames: fileNames,
        images,
        folderName: "usersrequestParts",
      });
    };
    const hasImages = await uploadImages();

    const bodyData = {
      name,
      email,
      description,
      phoneNumber,
      images: hasImages
        ? hasImages.map((img) => {
            return { url: img?.url.split("?")[0] };
          })
        : [],
    };

    const data: OrderResponse = await fetchRequest<OrderResponse>({
      url: `${process.env.REACT_APP_BACKEND_DOMAIN}/request-part`,
      method: "POST",
      body: { ...bodyData },
      dispatch,
    });
    if (data.success && !data.error) {
      dispatch(showThankYou());
      dispatch(resetCart());
    }
    return data;
  }
);

export const { showThankYou, resetRequestParts } = userRequestPart.actions;
export const userRequestPartReducer = userRequestPart.reducer;
