import {
  Button,
  Collapse,
  Divider,
  Pagination,
  Select,
  Slider,
  Tag,
} from "antd";
import "./styles/productPagesLayout.css";
import { useEffect, useRef, useState } from "react";
import { footerRoutes } from "../footer/footer";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { LoadingOutlined, FilterOutlined } from "@ant-design/icons";
import {
  clearKeywordFilter,
  filterPrice,
  getCarmakesModelsThunk,
  getCategoriesCountThunk,
  getProductsThunk,
  pagination,
  setSearchKeyword,
  sortByPrice,
} from "../state/productsSlice";
import { useWindowDimensions } from "../home/home";
import { CaretDownOutlined } from "@ant-design/icons";
import {
  useAppDispatch,
  useAppSelector,
} from "../../hooks/useAppDispatchSelector";
import carImage from "./styles/images/car-listing-img.jpeg";
import carImageFooter from "./styles/images/car-listing-image-2.jpeg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import MultiSelectAccordion from "./multiSelectAccordion";
import { useUpdateSearchParams } from "../../hooks/useUpdateSearchParams";
import Drawer from "./drawer";

const CategoriesFilter = ({
  onClickHandler,
}: {
  onClickHandler?: () => void;
}) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const loadingCategoriesCount = useAppSelector(
    (state) => state.productsReducer.loadingCategoriesCount
  );
  const categoriesCount = useAppSelector(
    (state) => state.productsReducer.categoriesCount
  );

  return (
    <div className="flex flex-col gap-5 py-5 lg:py-0 px-7 lg:px-0 ">
      {categoriesCount.map((cat) => {
        let className: string = `flex justify-between items-center text-2xl text-gray_primary `;
        if (
          location.pathname ===
          `/products/${cat?.categoryName?.replace(" ", "").toLowerCase()}`
        ) {
          className = `flex justify-between items-center text-2xl !text-blue_primary `;
        }
        if (
          cat?.categoryName?.replace(" ", "").toLowerCase() === "allproducts"
        ) {
          className = className + `-order-1`;
        }

        const searchQuery = searchParams.toString()?.replace(/%2C/g, ",");

        const fullRoute = `products/${cat?.categoryName
          ?.replaceAll(" ", "")
          .toLowerCase()}${searchQuery ? `?${searchQuery}` : ""}`;

        return (
          <div
            key={cat?.categoryName}
            className={className}
            onClick={() => {
              dispatch(
                pagination({
                  pageNumber: 1,
                })
              );
            }}
          >
            <Link
              onClick={() => {
                if (onClickHandler) {
                  onClickHandler();
                }
              }}
              to={fullRoute}
              className={`${
                location.pathname === cat?.categoryName
                  ? ""
                  : "cursor-pointer hover:text-main_color_darker"
              } capitalize`}
            >
              {cat?.categoryName}
            </Link>
            {loadingCategoriesCount ? (
              <div className="flex items-end justify-end ">
                <LoadingOutlined className="text-2xl" />
              </div>
            ) : (
              <p>({cat?.productCount})</p>
            )}
          </div>
        );
      })}
    </div>
  );
};
const CategoriesCountSection = () => {
  return (
    <div>
      <h2 className="capitalize text-4xl font-semibold flex flex-col mb-10 text-gray_primary">
        Categories
      </h2>
      <CategoriesFilter />
    </div>
  );
};
const CategoryCollapse = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="w-full border rounded-lg py-2 bg-white">
      {/* Toggle Button */}
      <div
        className="w-full flex justify-center items-center gap-2 py-2 text-2xl font-semibold text-gray_primary"
        onClick={() => setIsOpen(!isOpen)}
      >
        Categories
        <CaretDownOutlined
          className={`h-6 w-6 mt-2 transform transition-transform duration-300 ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
        />
      </div>

      <div
        className={`overflow-hidden transition-all duration-300 ease-in-out ${
          isOpen ? "max-h-96 opacity-100 " : "max-h-0 opacity-0"
        }`}
      >
        <CategoriesFilter
          onClickHandler={() => {
            setIsOpen(false);
          }}
        />
      </div>
    </div>
  );
};
export default function ProductsPagesLayout() {
  const { width } = useWindowDimensions();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { category } = useParams();
  const dispatch = useAppDispatch();
  const updateParams = useUpdateSearchParams();
  const productsNumber = useAppSelector(
    (state) => state.productsReducer.productsNumber
  );
  const activeFilters = useAppSelector(
    (state) => state.productsReducer.activeFilters
  );
  const highestPrice = useAppSelector(
    (state) => state.productsReducer.highestPrice
  );
  const loadingProducts = useAppSelector(
    (state) => state.productsReducer.loadingProducts
  );

  const minParam = searchParams.get("min");
  const maxParam = searchParams.get("max");

  const [sliderValue, setSliderValue] = useState({
    min: Number(minParam) || 0,
    max: Number(maxParam) || highestPrice,
  });

  useEffect(() => {
    dispatch(
      getProductsThunk({
        category: category || "allProducts",
      })
    );
  }, [category, searchParams]);

  useEffect(() => {
    dispatch(getCarmakesModelsThunk());
  }, []);

  useEffect(() => {
    dispatch(getCategoriesCountThunk());
  }, [searchParams]);

  useEffect(() => {
    if (highestPrice === 0 || minParam) return;

    setSliderValue((prev) => {
      return { min: prev.min, max: highestPrice };
    });
  }, [highestPrice]);

  const pageNumebr = useAppSelector(
    (state) => state.productsReducer.pageNumber
  );
  const makeModels = useAppSelector((state) => state.productsReducer.carMakes);

  const endingNumber = pageNumebr * 12;
  const startinNumber = endingNumber - 12;

  const resetPriceFilter = () => {
    setSliderValue({ min: 0, max: Math.round(highestPrice / 2) });
    updateParams({
      max: null,
      min: null,
    });
  };
  const myRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    myRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [pageNumebr, location.pathname]);

  return (
    <div className="bg-gray-50">
      <div className="lg:h-[60vh] h-[50vh] w-full relative flex">
        <div className="w-full h-full absolute top-0 bg-[#393e5187] z-[1]" />
        <LazyLoadImage
          src={carImage}
          alt="car"
          className="absolute object-cover object-center lg:object-bottom  w-full h-full"
        />
        <div className="z-[1] lg:mt-auto lg:mb-10 text-white lg:text-6xl text-5xl container mx-auto relative uppercase text-center flex font-semibold">
          <div className="flex flex-col gap-5 lg:mx-0 mx-auto my-auto">
            <p>parts you need</p>
            <p>speed you deserve</p>
          </div>
        </div>
      </div>
      <div className="container mx-auto pb-20 pt-20 lg:pt-56 h-max lg:grid lg:grid-cols-[minmax(120px,320px)_minmax(600px,_1fr)] px-10">
        {width >= 1024 && (
          <div className="flex flex-col h-max gap-28 pr-10">
            <div className="flex flex-col w-full">
              <h2 className="capitalize text-4xl font-semibold text-gray_primary">
                Filter by price
              </h2>
              <Slider
                styles={{
                  track: {
                    background: "transparent",
                  },
                  tracks: {
                    background: `#333399`,
                  },
                }}
                onChange={(value: number[]) => {
                  setSliderValue({
                    min: value[0],
                    max: value[1],
                  });
                }}
                range={{ draggableTrack: true }}
                defaultValue={[sliderValue.min, sliderValue.max]}
                value={[sliderValue.min, sliderValue.max]}
                max={highestPrice}
                step={10}
              />
              <div className="flex justify-between items-start flex-col gap-5">
                <div className="text-2xl flex">
                  <p className="mr-5 text-gray_primary">Price:</p>
                  <div className="font-semibold flex gap-1">
                    <p>{sliderValue.min} EGP</p>
                    <p>-</p>
                    <p>{sliderValue.max} EGP</p>
                  </div>
                </div>
                <div className="flex gap-5 items-center">
                  <Button
                    onClick={() => {
                      resetPriceFilter();
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    onClick={() => {
                      updateParams({
                        min: sliderValue.min,
                        max: sliderValue.max,
                      });
                    }}
                    className="filterButton"
                  >
                    Filter
                  </Button>
                </div>
              </div>
            </div>
            <CategoriesCountSection />
            <div>
              <h2 className="capitalize text-4xl font-semibold flex flex-col mb-10 text-gray_primary">
                Make & Model
              </h2>
              <MultiSelectAccordion carMakeData={makeModels} />
            </div>
          </div>
        )}
        <div
          className="bg-white flex flex-col items-center lg:gap-20 gap-10 px-5 lg:px-12 xl:px-20 2xl:px-32 py-20"
          ref={myRef}
        >
          <h2 className=" text-4xl lg:text-6xl font-semibold border-b-1 capitalize border-b-blue_primary px-10 pb-5 w-[85vw] sm:w-[34rem] text-center text-gray_primary">
            {activeFilters.find((filter) => filter.type === "keyword") ===
            undefined
              ? footerRoutes?.filter((category) => {
                  return category.route == location.pathname;
                })[0]?.title
              : "Filtered Products"}
          </h2>
          <div className="w-full flex flex-col gap-10 sm:gap-20">
            <div className="lg:text-2xl text-lg flex flex-col sm:gap0 gap-10 sm:flex-row justify-between w-full items-center">
              <div className="flex flex-col gap-3 w-max">
                <p className="text-gray_primary ">
                  Showing{" "}
                  <span className="font-semibold">
                    {startinNumber + 1} -{" "}
                    {endingNumber > productsNumber
                      ? productsNumber
                      : endingNumber}
                  </span>{" "}
                  of <span className="font-semibold">{productsNumber}</span>{" "}
                  Results
                </p>
                {activeFilters?.length > 0 && width >= 1024 && (
                  <p className="flex gap-2">
                    Active Filters:
                    {activeFilters.map((filter, i) => (
                      <Tag
                        closable={true}
                        className="font-semibold capitalize flex text-xl w-max mt-1"
                        key={i}
                        onClose={() => {
                          if (filter.type === "price") {
                            resetPriceFilter();
                          } else if (filter.type === "keyword") {
                            dispatch(setSearchKeyword(undefined));
                            navigate({
                              pathname: "/products/allproducts",
                            });
                            dispatch(clearKeywordFilter());
                          }
                        }}
                      >
                        {filter.keyword}
                      </Tag>
                    ))}
                  </p>
                )}
              </div>
              <div className="flex justify-between items-center w-full sm:w-max gap-10 h-12">
                <Select
                  onChange={(e) => {
                    dispatch(sortByPrice(e));
                  }}
                  defaultValue={"default"}
                  className="lg:w-96 sm:w-96 w-full  sorting h-full lg:h-max"
                  options={[
                    { value: "default", label: "Default Sorting" },
                    {
                      value: "priceHigh",
                      label: "Sort By Price: High to Low ",
                    },
                    {
                      value: "priceLow",
                      label: "Sort By Price: Low to High ",
                    },
                  ]}
                />
                <div className="lg:hidden w-max h-full">
                  <Drawer
                    icon={<FilterOutlined />}
                    buttonText="Filters"
                    title="Filters"
                    Content={
                      <div className="flex flex-col w-full px-6">
                        <div>
                          <h2 className="capitalize text-2xl font-semibold text-center text-gray_primary">
                            Filter by price
                          </h2>
                          <Slider
                            styles={{
                              track: {
                                background: "transparent",
                              },
                              tracks: {
                                background: `#333399`,
                              },
                            }}
                            onChange={(value: number[]) => {
                              setSliderValue({
                                min: value[0],
                                max: value[1],
                              });
                            }}
                            range={{ draggableTrack: true }}
                            defaultValue={[sliderValue.min, sliderValue.max]}
                            value={[sliderValue.min, sliderValue.max]}
                            max={highestPrice}
                            step={10}
                          />
                          <div className="flex items-start flex-col gap-5">
                            <div className="text-xl flex w-full justify-center ">
                              <p className="mr-5 text-gray_primary">Price:</p>
                              <div className="font-semibold flex gap-1">
                                <p>{sliderValue.min} EGP</p>
                                <p>-</p>
                                <p>{sliderValue.max} EGP</p>
                              </div>
                            </div>
                            <div className="flex gap-5 items-center justify-center w-full">
                              <Button
                                className="!text-xl"
                                onClick={() => {
                                  resetPriceFilter();
                                }}
                              >
                                Reset
                              </Button>
                              <Button
                                className="!text-xl filterButton"
                                onClick={() => {
                                  updateParams({
                                    min: sliderValue.min,
                                    max: sliderValue.max,
                                  });
                                }}
                              >
                                Filter
                              </Button>
                            </div>
                          </div>
                        </div>
                        <Divider />
                        <div>
                          <h2 className="capitalize text-2xl font-semibold flex flex-col mb-10 text-gray_primary text-center ">
                            Make & Model
                          </h2>
                          <MultiSelectAccordion carMakeData={makeModels} />
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
            {activeFilters?.length > 0 && width < 1024 && (
              <p className="flex gap-2 text-2xl justify-center sm:justify-start">
                Active Filters:{" "}
                {activeFilters.map((filter: any, i) => (
                  <Tag
                    closable={true}
                    className="font-semibold capitalize flex text-xl w-max "
                    key={i}
                    onClose={() => {
                      if (filter.type === "price") {
                        resetPriceFilter();
                      } else if (filter.type === "keyword") {
                        dispatch(setSearchKeyword(undefined));
                        navigate({
                          pathname: "/products/allproducts",
                        });
                        dispatch(clearKeywordFilter());
                      }
                    }}
                  >
                    {filter.keyword}
                  </Tag>
                ))}
              </p>
            )}

            <div className="lg:hidden">
              <CategoryCollapse />
            </div>

            {loadingProducts ? (
              <div className="flex items-center justify-center h-[20vh] mx-auto">
                <LoadingOutlined className="text-7xl" />
              </div>
            ) : (
              <div className="w-full grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-20">
                <Outlet />
              </div>
            )}
            <Pagination
              onChange={(e) => {
                dispatch(pagination({ pageNumber: e }));
              }}
              className="mx-auto"
              defaultCurrent={1}
              current={pageNumebr}
              pageSize={12}
              total={productsNumber}
            />
          </div>
        </div>
      </div>
      <div className="bg-black w-full h-[30vh] relative flex">
        <div className="w-full h-full absolute top-0 bg-[#393e51ba] z-[1]" />
        <LazyLoadImage
          src={carImageFooter}
          alt="car"
          className="absolute object-cover object-bottom w-full h-full"
        />{" "}
        <div className="z-[1] lg:mb-10 mb-auto mt-auto text-white_primary text-5xl container mx-auto relative uppercase text-center flex font-semibold">
          <div className="lg:mb-10  lg:block flex flex-col lg:justify-center mx-auto lg:mx-0">
            <span>parts you need </span>
            <span>speed you deserve</span>
          </div>
        </div>
      </div>
    </div>
  );
}
