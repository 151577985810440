import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
type HomeServiceCardProps = {
  icon: IconDefinition;
  title: string;
  paragraph: string;
};
export default function HomeServiceCard({
  icon,
  title,
  paragraph,
}: HomeServiceCardProps) {
  return (
    <div className="flex flex-col items-center w-[250px] relative h-[250px]  justify-center  rounded-full">
      <div className="h-max w-max">
        <FontAwesomeIcon
          icon={icon}
          color="#333399"
          size="5x"
          className="mb-10"
        />
      </div>
      <p
        style={{
          letterSpacing: "0.2px",
        }}
        className="text-3xl mb-3 font-bold capitalize pb-2 px-5 text-center h-max text-gray_primary w-full "
      >
        {title}
      </p>
      <p className="text-2xl font-semibold text-center text-gray_primary">
        {paragraph}
      </p>
    </div>
  );
}
