import { LazyLoadImage } from "react-lazy-load-image-component";
import ourService from "../../assets/ourService.jpeg";
import servicesTwo from "../../assets/service-2.jpeg";
import servicesThree from "../../assets/service-3.jpeg";
import servicesFour from "../../assets/service-4.jpeg";
import servicesFive from "../../assets/service-5.jpeg";
import servicesSix from "../../assets/service-6.jpeg";
import HomeImageSection from "../home/HomeImageSection";
import { Divider } from "antd";
const RightImageSection = ({
  header,
  text,
  image,
}: {
  header: string;
  text: string;
  image: string;
}) => {
  return (
    <div className="flex lg:flex-row flex-col items-center lg:gap-24 gap-10">
      <div className="lg:w-2/3 w-full flex flex-col gap-10 items-center lg:items-start text-center lg:text-left lg:px-0 px-10">
        <p className="md:text-5xl text-4xl text-blue_primary font-bold">
          {header}
        </p>
        <p className="text-gray_primary md:text-3xl text-2xl leading-[25px] lg:pr-10 ">
          {text}
        </p>
      </div>
      <div className="lg:w-1/3 w-full">
        <LazyLoadImage
          className="object-fill h-[400px]  rounded-lg shadow-xl lg:mx-0 mx-auto"
          src={image}
          alt="service-1"
        />
      </div>
    </div>
  );
};
const LeftImageSection = ({
  header,
  text,
  image,
}: {
  header: string;
  text: string;
  image: string;
}) => {
  return (
    <div className="flex lg:flex-row flex-col items-center lg:gap-24 gap-10 lg:items-start text-center lg:text-left lg:px-0 px-10">
      <div className="lg:w-1/3 w-full">
        <LazyLoadImage
          className="object-fill h-[400px]  rounded-lg shadow-xl lg:mx-0 mx-auto"
          src={image}
          alt="service-1"
        />
      </div>
      <div className="lg:w-2/3 w-full flex flex-col gap-10 order-[-1] lg:order-1">
        <p className="md:text-5xl text-4xl text-blue_primary font-bold">
          {header}
        </p>
        <p className="text-gray_primary md:text-3xl text-2xl leading-[25px]">
          {text}
        </p>
      </div>
    </div>
  );
};
const OurService = () => {
  return (
    <div className="relative top-0 left-0 z-10">
      <div className="w-screen h-screen relative flex items-center justify-center flex-col">
        <LazyLoadImage
          src={ourService}
          alt="main-image"
          className="h-full w-full absolute left-0 top-0 object-cover"
        />
        <div className="w-full h-full bg-[#393E51] opacity-50 top-0 left-0 absolute" />
        <div className=" flex flex-col gap-2 z-10 relative container mx-auto w-max ">
          <p className="uppercase text-white_primary md:text-[40px] text-5xl font-bold">
            g-fast services
          </p>
        </div>
      </div>
      <div className="container mx-auto bg-white py-20">
        <div className="flex flex-col gap-10">
          <RightImageSection
            header="Our Services"
            text="At G-FAST, we are committed to transforming the spare parts
              industry by providing a seamless, efficient, and transparent
              digital platform for vehicle owners. Our services go beyond just
              selling spare parts—we offer a comprehensive support system that
              ensures every customer receives the right product, expert
              guidance, and a smooth repair experience."
            image={servicesSix}
          />
          <div className="w-[90%] mx-auto">
            <Divider className="lg:my-[24px] my-[16px]" />
          </div>
          <LeftImageSection
            header="1. Online Spare Parts Marketplace"
            text="G-FAST offers a user-friendly e-commerce platform where customers can Browse and purchase high-quality body spare parts for various vehicle brands. Get real-time access to transparent pricing—no hidden fees, no middlemen. Compare spare parts by quality and cost-effectiveness to make informed decisions."
            image={servicesTwo}
          />
          <div className="w-[90%] mx-auto">
            <Divider className="lg:my-[24px] my-[16px]" />
          </div>
          <RightImageSection
            header="2. Specialized Spare Parts for MG Vehicles"
            text="We specialize in MG spare parts (models from 2018 onwards), ensuring that MG owners have access to genuine, high-quality components at competitive prices. Our inventory includes bumpers, fenders, headlights, grilles, and other essential body parts to help customers restore their vehicles after accidents. Customers can request custom parts that are not listed on our website with just one click."
            image={servicesThree}
          />
          <div className="w-[90%] mx-auto">
            <Divider className="lg:my-[24px] my-[16px]" />
          </div>
          <LeftImageSection
            header="3. Multi-Brand Spare Parts Expansion"
            text="We are actively expanding our stock to include spare parts for: Fiat (Tipo models)z Hyundai Kia And more brands coming soon!"
            image={servicesFour}
          />
          <div className="w-[90%] mx-auto">
            <Divider className="lg:my-[24px] my-[16px]" />
          </div>
          <RightImageSection
            header="4. Warranty & Returns Policy"
            text="We follow a strict warranty and return policy to ensure that all parts meet the highest quality standards. Customers can exchange or return parts if they do not match the described specifications. This policy minimizes risks and gives customers peace of mind when shopping with G-FAST."
            image={servicesFive}
          />
          <div className="w-[90%] mx-auto">
            <Divider className="lg:my-[24px] my-[16px]" />
          </div>
          <LeftImageSection
            header="5. Expert Guidance & Technical Support"
            text=" Our team of specialists provides customers with the best recommendations for their vehicle repair needs. We offer full guidance on the quality, compatibility, and installation of spare parts. Customers receive technical support throughout the process, from selecting the right part to completing the repair."
            image={servicesThree}
          />
          <div className="w-[90%] mx-auto">
            <Divider className="lg:my-[24px] my-[16px]" />
          </div>
          <RightImageSection
            header="6. Accident Analysis Reports (FREE Service)"
            text="Customers who have been in an accident can request a free accident damage report. Our specialists assess the extent of vehicle damage and recommend the necessary spare parts for repair. This service helps vehicle owners make informed decisions quickly."
            image={servicesSix}
          />
          <div className="w-[90%] mx-auto">
            <Divider className="lg:my-[24px] my-[16px]" />
          </div>
          <LeftImageSection
            header="7. Fast & Reliable 24-Hour Delivery"
            text="Orders are processed and delivered within 24 hours, ensuring customers get their spare parts as quickly as possible. We provide nationwide delivery, covering all major cities in Egypt. Customers can track their orders in real-time through our platform."
            image={servicesThree}
          />
          <div className="w-[90%] mx-auto">
            <Divider className="lg:my-[24px] my-[16px]" />
          </div>
          <RightImageSection
            header="8.Custom Spare Parts Requests"
            text=" Customers who have been in an accident can request a free accident damage report. Our specialists assess the extent of vehicle damage and recommend the necessary spare parts for repair. This service helps vehicle owners make informed decisions quickly."
            image={servicesSix}
          />
          <div className="w-[90%] mx-auto">
            <Divider className="lg:my-[24px] my-[16px]" />
          </div>
          <LeftImageSection
            header="9. Aftersales & Ongoing Support"
            text="Our commitment to customers doesn’t stop after a purchase.We provide aftersales support to assist customers with installation, troubleshooting, and part maintenance. If customers need further guidance, our team is always available to answer questions."
            image={servicesThree}
          />
        </div>
      </div>
      <div className="w-[90%] mx-auto">
        <Divider className="!m-0" />
      </div>
      <HomeImageSection />
    </div>
  );
};
export default OurService;
