import { useState } from "react";
const Drawer = ({
  Content,
  title,
  buttonText,
  icon,
}: {
  Content: React.ReactNode;
  title: string;
  buttonText: string;
  icon?: any;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative z-10 h-full">
      <div
        className=" h-full border border-blue_primary rounded-lg text-blue_primary  px-4 flex items-center text-[14px]"
        onClick={() => setIsOpen(true)}
      >
        {buttonText}
        {icon && <span className="ml-2">{icon}</span>}
      </div>

      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity ${
          isOpen ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
        onClick={() => setIsOpen(false)}
      ></div>

      <div
        className={`fixed top-0 left-0 h-full w-[70vw] bg-white shadow-lg transform transition-transform flex flex-col gap-10 ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="p-4 flex justify-between items-center border-b">
          <h2 className="text-2xl text-center font-semibold">{title}</h2>
          <button onClick={() => setIsOpen(false)}>
            <p className="text-xl font-semibold">X</p>
          </button>
        </div>
        {Content}
      </div>
    </div>
  );
};

export default Drawer;
