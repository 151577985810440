import { Button, Form, Input } from "antd";
import AdminUploadImage from "../common/adminUploadImage";
import Dragger from "antd/es/upload/Dragger";
import { FC, useEffect, useState } from "react";
import { FileItem } from "../state/adminProductsSlice";
import { InboxOutlined, ArrowRightOutlined } from "@ant-design/icons";
import {
  useAppDispatch,
  useAppSelector,
} from "../../hooks/useAppDispatchSelector";

import { CheckOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { BackendStringResponses } from "../../util/constants";
import {
  resetEstimations,
  userCreateEstimationThunk,
} from "../state/userEstimationSlice";
import { CheckCircleOutlined } from "@ant-design/icons";

const EstimationForm: FC<{ getUserName: (name: string) => void }> = ({
  getUserName,
}) => {
  const [images, setImages] = useState<FileItem[] | []>([]);
  const dispatch = useAppDispatch();
  const loading = useAppSelector(
    (state) => state.userEstimationReducer.loadingResponse
  );
  const isAuth = useAppSelector(
    (state) => state.userAuthenticationReducer.userAuthenticated
  );

  const estimationCreated = useAppSelector(
    (state) => state.userEstimationReducer.estimationCreated
  );
  const errorMessage = useAppSelector(
    (state) => state.userEstimationReducer.errorMessage
  );
  const user = useAppSelector((state) => state.userAuthenticationReducer.user);
  const [estimationForm] = Form.useForm();
  function compareArraysByProperty(
    arr1: FileItem[],
    arr2: FileItem[]
  ): FileItem[] {
    const map = new Map<string, FileItem>();

    // Add all objects from arr1 to the map
    arr1.forEach((item) => {
      if (item.name) {
        map.set(item["name"], item);
      }
    });

    // Add objects from arr2 to the map only if their 'name' property doesn't already exist
    arr2.forEach((item) => {
      if (item.name) {
        if (!map.has(item["name"])) {
          map.set(item["name"], item);
        }
      }
    });

    return Array.from(map.values());
  }
  const removeAdditionalImage = (fileName: FileItem) => {
    const newImages = images?.filter((photo) => photo.uid !== fileName.uid) as
      | FileItem[]
      | [];
    setImages(newImages);
  };
  const handleSubmit = () => {
    estimationForm
      .validateFields()
      .then(
        async (values: {
          name: string;
          email: string;
          phoneNumber: string;
          description: string;
          make: string;
          model: string;
        }) => {
          const { make, model, phoneNumber, name, email, description } = values;
          getUserName(values.name);

          await dispatch(
            userCreateEstimationThunk({
              phoneNumber,
              name,
              email,
              description,
              images,
              make,
              model,
            })
          );
        }
      );
  };
  useEffect(() => {
    if (estimationCreated) {
      setImages([]);
      estimationForm.resetFields();
    }
  }, [estimationCreated]);
  useEffect(() => {
    if (isAuth) {
      estimationForm.setFieldsValue({ email: user?.email });
    } else {
      estimationForm.resetFields(["email"]);
    }
  }, [isAuth]);
  return (
    <Form
      onFinish={handleSubmit}
      form={estimationForm}
      className="mt-10 border rounded-lg p-5 border-dashed"
    >
      <div className="flex lg:flex-row flex-col lg:gap-10 w-full justify-between">
        <Form.Item
          labelCol={{ span: 24 }}
          name={"name"}
          className="flex-1"
          label="Your Name/الأسم"
          required
          rules={[
            {
              validator: (_, value) => {
                if (value && value.trim().length !== 0) {
                  // If the value is not empty and doesn't contain any whitespace
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Please enter a valid product name")
                );
              },
              type: "string",
              required: true,
              message: "Name field is required",
            },
          ]}
        >
          <div>
            <input
              type="text"
              className="border p-3 rounded-lg focus:outline-none w-full text-[16px] sm:text-2xl"
              placeholder="Name"
            />
            {errorMessage &&
              errorMessage === BackendStringResponses.NAME_ERROR && (
                <p className="text-red-600">Name field is required</p>
              )}
          </div>
        </Form.Item>
        <Form.Item
          labelCol={{ span: 24 }}
          name={"phoneNumber"}
          className="flex-1"
          label="Phone Number/رقم الهاتف"
          required
          rules={[
            {
              type: "string",
              required: true,
              message: "Phone Number field is required",
            },
          ]}
        >
          <div>
            <input
              type="number"
              className="border p-3 rounded-lg focus:outline-none w-full text-[16px] sm:text-2xl"
              placeholder="Phone number"
            />
            {errorMessage &&
              errorMessage === BackendStringResponses.PHONENUMBER_ERROR && (
                <p className="text-red-600">Phone Number field is required</p>
              )}
          </div>
        </Form.Item>
      </div>
      <div className="flex lg:flex-row flex-col lg:gap-10 w-full justify-between">
        <Form.Item
          labelCol={{ span: 24 }}
          name={"make"}
          className="flex-1"
          label="Car make/أسم ماركه السياره"
          required
          rules={[
            {
              validator: (_, value) => {
                if (value && value.trim().length !== 0) {
                  // If the value is not empty and doesn't contain any whitespace
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Please enter a valid product name")
                );
              },
              type: "string",
              required: true,
              message: "Make field is required",
            },
          ]}
        >
          <div>
            <input
              type="text"
              className="border p-3 rounded-lg focus:outline-none w-full text-[16px] sm:text-2xl"
              placeholder="Car Make"
            />
            {errorMessage &&
              errorMessage === BackendStringResponses.MAKE_ERROR && (
                <p className="text-red-600">make field is required</p>
              )}
          </div>
        </Form.Item>
        <Form.Item
          labelCol={{ span: 24 }}
          name={"model"}
          className="flex-1"
          label="Car Model/أسم موديل السياره"
          required
          rules={[
            {
              validator: (_, value) => {
                if (value && value.trim().length !== 0) {
                  // If the value is not empty and doesn't contain any whitespace
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Please enter a valid product name")
                );
              },
              type: "string",
              required: true,
              message: "Model field is required",
            },
          ]}
        >
          <div>
            <input
              type="text"
              className="border p-3 rounded-lg focus:outline-none w-full text-[16px] sm:text-2xl"
              placeholder="Car Model"
            />
            {errorMessage &&
              errorMessage === BackendStringResponses.MODEL_ERROR && (
                <p className="text-red-600">MODEL field is required</p>
              )}
          </div>
        </Form.Item>
      </div>
      <div className="flex lg:flex-row flex-col lg:gap-10 w-full justify-between ">
        <Form.Item
          labelCol={{ span: 24 }}
          name={"email"}
          className="flex-1"
          label="E-Mail"
          validateTrigger="onBlur"
          rules={[
            {
              type: "email",
              required: false,
              message: "Email field is in-correct format",
            },
          ]}
        >
          <div>
            <input
              defaultValue={isAuth ? user?.email : ""}
              type="text"
              className="border p-3 rounded-lg focus:outline-none w-full text-[16px] sm:text-2xl"
              placeholder="E-Mail"
            />
            {errorMessage &&
              errorMessage === BackendStringResponses.EMAIL_ERROR && (
                <p className="text-red-600">Email field is required</p>
              )}
          </div>
        </Form.Item>
      </div>
      <div className="flex lg:flex-row flex-col lg:gap-10 w-full justify-between">
        <Form.Item
          labelCol={{ span: 24 }}
          name={"description"}
          className="flex-1"
          label="Damage description/وصف الحادث"
          required
          rules={[
            {
              validator: (_, value) => {
                if (value && value.trim().length !== 0) {
                  // If the value is not empty and doesn't contain any whitespace
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Please enter a valid product name")
                );
              },
              type: "string",
              required: true,
              message: "Damage description field is required",
            },
          ]}
        >
          <div>
            <Input.TextArea
              rows={10}
              className="border p-3 rounded-lg focus:outline-none w-full text-[16px] sm:text-2xl"
              placeholder="Damage Description"
              showCount
              maxLength={10000}
              minLength={5}
              style={{
                resize: "none",
              }}
            />
            {errorMessage &&
              errorMessage === BackendStringResponses.DESCRIPTION_ERROR && (
                <p className="text-red-600">Description field is required</p>
              )}
          </div>
        </Form.Item>
      </div>
      <div className="w-full h-max flex flex-col">
        <Form.Item
          labelCol={{ span: 24 }}
          onReset={() => {
            setImages([]);
          }}
          label="Additional Photos"
          className="w-full h-max mb-5"
          name="images"
          rules={[
            {
              required: false,
              validateTrigger: "onSubmit",
              validator: (_, value) => {
                const hasError = images.filter((photo) => photo.error === true);

                if (hasError.length > 0) {
                  return Promise.reject(new Error("Error: File Size"));
                } else return Promise.resolve();
              },
            },
          ]}
        >
          <Dragger
            accept=".jpg, .jpeg, .png"
            name="file"
            customRequest={({ onError }) => {
              onError && onError(new Error("Upload failed"));
            }}
            beforeUpload={(file, fileList) => {
              const additionalPhotoFile: FileItem = file;
              additionalPhotoFile.url = URL.createObjectURL(file);
              let filesArray =
                fileList.length <= 4 ? fileList : fileList.slice(0, 5);
              const isLt5M = file.size / 1024 / 1024 <= 5;
              if (!isLt5M) {
                additionalPhotoFile.error = true;
                additionalPhotoFile.errorMessage =
                  "Error: Please upload a file that is smaller than 5MB";
                setImages((prev) => [...prev, additionalPhotoFile]);
                return;
              }
              if (images.length <= 4) {
                const uniqueItems = compareArraysByProperty(filesArray, images);
                setImages(uniqueItems);

                // Prevent upload
                return false;
              }
            }}
            listType="picture"
            itemRender={() => {
              return null;
            }}
            multiple={true}
            maxCount={5}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support jpg, .jpeg, .png, Max images number for cover is one
              image. The image should not be more than 5MB
            </p>
          </Dragger>
        </Form.Item>
        <div className="h-max w-full">
          {images &&
            images.slice(0, 5).map((photo) => {
              if (photo.error) {
                return (
                  <AdminUploadImage
                    type="additionalPhoto"
                    key={photo.uid}
                    name={photo.name}
                    remove={removeAdditionalImage}
                    thumbUrl={photo.url}
                    originalPhoto={photo}
                    error={photo.error}
                    errorMessage={photo.errorMessage}
                  />
                );
              }
              return (
                <AdminUploadImage
                  type="additionalPhoto"
                  key={photo.uid}
                  name={photo.name}
                  remove={removeAdditionalImage}
                  thumbUrl={photo.url}
                  originalPhoto={photo}
                  error={false}
                  errorMessage={undefined}
                />
              );
            })}
          <p className="ml-auto text-gray-400 w-max">
            {images.length}/5 files uploaded
          </p>
        </div>
      </div>
      <Button
        loading={loading}
        onClick={() => {
          estimationForm.submit();
        }}
        className="bg-blue_primary text-white flex items-center justify-between gap-3 mx-auto mt-10"
      >
        <p>Get My Estimation</p>
        <ArrowRightOutlined />
      </Button>
      <p className="w-max mx-auto text-lg text-blue_primary">
        Get your estimate within 24 hrs!
      </p>
    </Form>
  );
};
const UserCreateEstimation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState<string | null>(null);
  const loading = useAppSelector(
    (state) => state.userEstimationReducer.loadingResponse
  );
  const estimationCreated = useAppSelector(
    (state) => state.userEstimationReducer.estimationCreated
  );
  useEffect(() => {
    dispatch(resetEstimations());
  }, []);
  const getUserName = (name: string) => {
    setName(name);
  };

  return (
    <div>
      {estimationCreated === false && (
        <>
          <p className="text-5xl font-semibold capitalize text-center">
            Request Estimation
          </p>
          <ul className="flex flex-col md:items-center  mt-10">
            <li className="text-xl md:mt-2 mt-4 text-green-900 font-semibold text-left flex items-center gap-4">
              <CheckCircleOutlined className="text-green-900 text-[14px] " />
              <p>
                We ensure that your vehicle is repaired using only the best
                original and high quality parts
              </p>
            </li>{" "}
            <li className="text-xl md:mt-2 mt-4 text-green-900 font-semibold text-left flex items-center gap-4">
              <CheckCircleOutlined className="text-green-900 text-[14px] " />
              <p>
                All installed parts come with a warranty, giving you a peace of
                mind on every repair
              </p>
            </li>
            <li className="text-xl md:mt-2 mt-4 text-green-900 font-semibold text-left flex items-center gap-4">
              <CheckCircleOutlined className="text-green-900 text-[14px] " />
              <p>
                our team will contact you immediately and is available around
                the clock to assist you with any concerns
              </p>
            </li>
            <li className="text-xl md:mt-2 mt-4 text-green-900 font-semibold text-left flex items-center gap-4">
              <CheckCircleOutlined className="text-green-900 text-[14px] " />
              <p>
                ⁠Get a detailed, free estimation with prices of repairs needed
              </p>
            </li>
          </ul>
          <EstimationForm getUserName={getUserName} />
        </>
      )}
      {!loading && estimationCreated && (
        <div className="flex flex-col items-center bg-gray-50 rounded-lg py-10 gap-5  mx-auto mt-44">
          <div className="flex items-center justify-center border p-3 rounded-full border-blue_primary">
            <CheckOutlined className="text-5xl text-blue_primary" />
          </div>
          <p className="text-2xl font-semibold">Hello, {name}</p>
          <p className="text-4xl font-semibold capitalize text-center">
            Your request is submitted
          </p>
          <p className="text-2xl font text-center">
            We will send you a confirmation email and we will contact you soon
          </p>
          <p className="text-2xl text-center font-semibold">
            You can check our products from here
          </p>
          <Button
            onClick={() => {
              navigate("/products/allproducts");
            }}
            className="bg-blue_primary text-white flex items-center justify-between gap-3 w-max "
          >
            <p>Products</p>
          </Button>
        </div>
      )}
    </div>
  );
};
export default UserCreateEstimation;
