import { useSearchParams, useNavigate } from "react-router-dom";
import { useCallback } from "react";

export function useUpdateSearchParams() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const updateParams = useCallback(
    (
      newParams: Record<string, string | number | (string | number)[] | null>
    ) => {
      const params = new URLSearchParams(searchParams);

      Object.entries(newParams).forEach(([key, value]) => {
        if (value === null) {
          params.delete(key); // Remove the key if value is null
        } else if (Array.isArray(value)) {
          params.set(key, value.map((v) => v.toString()).join(","));
        } else {
          params.set(key, value.toString());
        }
      });

      // Ensure commas are not encoded
      const updatedQueryString = decodeURIComponent(params.toString());

      navigate(`?${updatedQueryString}`, { replace: true });
    },
    [searchParams, navigate] // Dependencies
  );

  return updateParams;
}
